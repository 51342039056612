<template>
  <div>
    <el-select
      class="tw_company_id_and_name_suggest_multiple_input"
      v-model="selectedCompanyIds"
      :placeholder="placeholder"
      :multiple="true"
      filterable
      @change="onChangeMultipleSelect"
    >
      <el-option
        v-for="company in allCompanies"
        :key="company.companyId"
        :label="`(${company.companyId}) / ${company.companyShortName}`"
        :value="company.companyId"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'TwCompanyIdAndNameSuggestMultipleSelect',
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Please Input'
    },
    allCompanies: {
      required: true,
      type: Array,
      default: () => []
    },
    originalSelectedCompanyIds: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      selectedCompanyIds: this.originalSelectedCompanyIds
    }
  },
  methods: {
    // 親コンポーネントより呼び出されるメソッド。
    clearSelection() {
      this.selectedCompanyIds = [];
    },
    onChangeMultipleSelect(selectedCompanyIds) {
      this.$emit('change', selectedCompanyIds);
    }
  }
};
</script>

<style lang="scss" scoped>
  .el-form-item {

    .tw_company_id_and_name_suggest_multiple_input {
      min-width: 750px;
    }
  }
</style>
