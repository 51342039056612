<template>
  <el-form-item
    v-if="isVisible"
    ref="formItem"
    :label="item.goodsTypeMasterList.goodsTypeNm"
    :prop="`linkageGoodsLineType[${item.index}].keyGrp.goodsValue`"
    :rules="rules"
    :class="{full: item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_TXT_AREA || item.goodsTypeMasterList.goodsTypeKbv === 'UNIT_ROUND_TYPE', add_hscode: isAddHsCode, remove_hscode: isRemoveHsCode}"
  >
    <el-radio-group v-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_KBV && item.goodsTypeMasterList.goodsTypeKbv === 'UNIT_ROUND_TYPE'" v-model="localValue.linkageGoodsLineType[item.index].keyGrp.goodsValue">
      <el-radio v-for="radio in UNIT_ROUND_TYPE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
    </el-radio-group>
    <el-select v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_KBV && item.goodsTypeMasterList.goodsTypeKbv === 'SYS_MST_UNIT'" v-model="localValue.linkageGoodsLineType[item.index].keyGrp.goodsValue" @change="onUnitChange" filterable clearable>
      <el-option
        v-for="unit in SYS_MST_UNIT"
        :key="unit.id"
        :label="unit.value"
        :value="unit.value">
      </el-option>
    </el-select>
    <el-input
      v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_TXT_AREA"
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 6}"
      placeholder=""
      v-model="localValue.linkageGoodsLineType[item.index].keyGrp.goodsValue"
      show-word-limit
      :maxlength="getMaxLength"
    />
    <TwInputFormatNumber
      v-else-if="item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_INT"
      v-model="numberModel"
    />
    <el-input v-else class="show_word_limit" v-model="localValue.linkageGoodsLineType[item.index].keyGrp.goodsValue" placeholder="" show-word-limit :maxlength="getMaxLength" />

    <!-- × HS Code削除ボタン -->
    <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isHsCode && isRemoveHsCode" @click="removeHsCode">
    <!-- + HS Code追加ボタン -->
    <tw-button type="secondary" size="medium" icon="plus" v-if="isHsCode && isAddHsCode" @click="addHsCode" class="add_button"></tw-button>
  </el-form-item>
</template>

<script>
import _ from 'lodash';
import { ATTR_TYPE, GOODS_ATTR_ID, UNIT_ROUND_TYPE_VARIABLES, PROCESS_TYPE } from 'lib-tw-common';
import { formatRequestDate, formatResponseDate, getMaxLength } from '@/utils/searchUtil.js';
import { createHsCode } from '@/utils/createGoodsLine';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwProcessGroupListEditGoodsLineTypeItem',
  props: {
    item: Object,
    value: Object,
    isHsCode: Boolean,
    selectGoodsLineSeq: Number,
  },
  inject: ['s'],
  components:{
    TwInputFormatNumber,
    TwButton,
  },
  data() {
    return {
      ATTR_TYPE,
      GOODS_ATTR_ID,
      UNIT_ROUND_TYPE_VARIABLES: _.map(UNIT_ROUND_TYPE_VARIABLES, o => {
        return {
          ...o,
          code: o.code.toString()
        }
      }),
      TwButton,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    numberModel: {
      get() {
        if (this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue === null || this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue === '') {
          return undefined;
        }
        if (_.isNaN(Number(this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue))) {
          return undefined;
        }
        return Number(this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue);
      },
      set(value) {
        if (value === undefined) {
          this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', null);
        } else {
          this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', value + '');
        }
      }
    },
    formatResponseDate: {
      get() {
        return formatResponseDate(this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue);
      },
      set(value) {
        value = formatRequestDate(value);
        this.s.setGoodsValue(this.tableName, this.item.index, this.groupName, this.item.key, value);
      }
    },
    isVisible() {
      if (this.item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_FILE_PATH) {
        return false;
      }
      return this.item.goodsTypeMasterList.goodsTypeNm;
    },
    SYS_MST_UNIT() {
      return this.$store.getters.getSysMstUnit;
    },
    rules() {
      let validationId = ['tw-isString'];
      if (this.item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_INT) {
        if (this.item.goodsTypeMasterList.goodsTypePrecision) {
          validationId.push(`tw-isIntegerDigit${this.item.goodsTypeMasterList.goodsTypePrecision}`);
        } else if (this.item.goodsTypeMasterList.goodsTypeLength) {
          validationId.push(`tw-isIntegerDigit${this.item.goodsTypeMasterList.goodsTypeLength}`);
        } else {
          validationId.push('tw-isMaxLength1');
        }
        if (this.item.goodsTypeMasterList.goodsTypeScale) {
          validationId.push(`tw-isDecimalDigit${this.item.goodsTypeMasterList.goodsTypeScale}`);
        } else if (this.item.goodsTypeMasterList.goodsTypeScale === 0) {
          validationId.push('tw-isDecimalDigit0');
        }
      } else if (this.item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_KBV) {
        return;
      } else {
        validationId.push(`tw-isMaxLength${this.item.goodsTypeMasterList.goodsTypeLength}`);
      }

      if (this.s.processType === PROCESS_TYPE.TPRBL) {
        if (this.isHsCode && _.get(this.item, 'keyGrp.seqNo') === 1) {
          validationId.push(`tw-isNotEmpty`);
        }
      }
      const rules = [
        {
          required: this.s.draftValid ? false : validationId.includes('tw-isNotEmpty'),
          lakeelMessageSourceFlg: true,
          validationId: validationId,
          validator: this.s.onEventValidation, trigger: [ATTR_TYPE.TYPE_TXT_AREA, ATTR_TYPE.TYPE_TXT_BOX, ATTR_TYPE.TYPE_INT].includes(this.item.goodsTypeMasterList.attrType) ? 'blur' : 'change'
        }
      ];
      return rules;
    },
    getMaxLength() {
      return getMaxLength(this.rules[0].validationId);
    },
    isAddHsCode() {
      const linkageGoodsLineType = _.filter(this.localValue.linkageGoodsLineType, o => o.keyGrp.goodsLineSeq === this.selectGoodsLineSeq);
      const maxSeqNo = _.get(_.maxBy(linkageGoodsLineType, 'keyGrp.seqNo'), 'keyGrp.seqNo');
      return _.get(this.item, 'keyGrp.attrId') == GOODS_ATTR_ID.HS_CODE &&_.get(this.item, 'keyGrp.goodsLineSeq') == this.selectGoodsLineSeq && _.get(this.item, 'keyGrp.seqNo') === maxSeqNo;
    },
    isRemoveHsCode() {
      return this.isAddHsCode && _.get(this.item, 'keyGrp.seqNo') > 1;
    },
  },
  created() {
    this.onUpdate();
  },
  methods: {
    onUpdate() {
      // 数値系の値がNULLの時はundefinedを代入
      if (this.item.goodsTypeMasterList.attrType === ATTR_TYPE.TYPE_INT && this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue === null) {
        this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', undefined);
      }
    },
    onRadioClick(val) {
      if (this.localValue.linkageGoodsLineType[this.item.index].keyGrp.goodsValue === val) {
        this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', null);
      } else {
        this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', val);
      }
    },
    onUnitChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, {value: value}), 'code');
      this.s.setGoodsValue('linkageGoodsLineType', this.item.index, 'keyGrp', 'goodsValue', unitId);
    },
    addHsCode() {
      this.localValue.linkageGoodsLineType.push(createHsCode(this.item));
    },
    removeHsCode() {
      this.localValue.linkageGoodsLineType.splice(this.item.index, 1);
    },
  }
};
</script>

<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 24px;
    width: 50%;
    display: flex;
    padding-right: 16px;

    &:last-child {
      margin-bottom: 20px;
    }

    &.full {
      width: 100%;
    }

    ::v-deep label.el-form-item__label {
      display: block;
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191A4;
      margin-right: 8px;
      padding-top: 2px;
    }

    &.remove_hscode {
      .el-input,
      .el-select {
        width: calc(346px - 20px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 20px) !important;
      }
      .remove_button {
        cursor: pointer;
        width: 20px;
        vertical-align: middle;
      }
      &.full {
        .remove_button {
          margin-bottom: 16px;
        }
      }
    }
    &.add_hscode {
      .el-input.show_word_limit {
        width: calc(346px - 48px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 48px) !important;
      }
      .add_button {
        vertical-align: initial;
        margin: 0 0 0 8px;
        padding-left: 9px;
        padding-right: 1px;
        &.group_button {
          border-radius: 10px;
        }
      }
      &.remove_hscode {
        .el-input {
          width: calc(346px - 68px) !important;
        }
        .el-textarea,
        .text_full {
          width: calc(100% - 68px) !important;
        }
      }
      &.full {
        .add_button {
          margin-bottom: 8px;
        }
        .remove_button {
          margin-bottom: 6px;
        }
      }
    }
  }

  ::v-deep .el-form-item__content {
    font-size: 14px;
    line-height: 20px;
    color: #3E3A39;
    vertical-align: top;
    flex: 1;

    .el-form-item__error {
      position: relative;
      top: 0;
      margin-bottom: -20px;
      max-width: 346px;
    }
  }

  ::v-deep textarea {
    resize: none;
  }

  .el-select {
    width: 270px;
  }

  .el-select.unit {
    width: 90px;
    margin-left: 12px;
  }

  .el-select.packing {
    margin-left: 12px;
  }

  .el-input-number {
    width: 168px;

    ::v-deep .el-input__inner {
      text-align: left;
    }
  }

  .el-input.show_word_limit {
    width: 270px;
  }

  .el-input-number.amount {
    width: 270px;
  }
  
</style>
