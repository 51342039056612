<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Usage Details')" />

    <div class='tw_container'>
      <!-- パンくず 検索エリア -->
      <div class='top_area'>
        <tw-breadcrumbs v-if='breadcrumbs.length' :pages='breadcrumbs' />

        <tw-search-field
          :isShow="displayItems !== null"
          :filters='filters'
          :params='params'
          :key-for-queries-and-filters='keyForQueriesAndFilters'
          :allCompanies='allCompanies'
          :originalSelectedCompanyIds='selectedCompanyIds'
          :searchTargetCompaniesForButtonDisplay='searchTargetCompaniesForButtonDisplay'
          @applyFilters='applyFilters'
        >
          <template v-if="displayItems !== null" slot='buttons'>
            <tw-button
               type="primary"
               size="small"
               :disabled="selectionItems.length <= 0"
               @click="tsvDownload(selectionItems)"
            >
              {{ $t('Label.TSV Download') }}
            </tw-button>
          </template>

        </tw-search-field>
      </div>

      <tw-table-system
        height='max-content'
        :schemas='schemasForTargetUserRole'
        :items='displayItems'
        :selection="true"
        :pageSize='params.lslConfig.query.limit'
        :totalCount='totalCount'
        :offset='params.lslConfig.query.offset'
        :serverPagination='true'
        :isLoading='isLoading'
        :rowKey="getRowKey"
        @selection-change="selectionChange"
        @paging='onPaging'
        ref="table"
      />
    </div>
  </div>
</template>

<script>
import TwHeader from '@/components/organisms/TwHeader';
import TwBreadcrumbs from '@/components/molecules/TwBreadcrumbs';
import TwSearchField from '@/components/organisms/TwSearchField';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import { $api } from '@/store/ApiClient';
import _ from 'lodash';
import filters from '@/dictionaries/filters/record/usageDetailsFilters.json';
import schemas from '@/dictionaries/recordUsageDetailsSchema.json';
import { GET_INVOICE_FLOWS_SUMMARIES_FOR_TW_ADMIN, GET_INVOICE_FLOWS_SUMMARIES_FOR_COMPANY_ADMIN, DOWNLOAD_INVOICE_FLOWS_TSV_FOR_TW_ADMIN, DOWNLOAD_INVOICE_FLOWS_TSV_FOR_COMPANY_ADMIN } from '@/api/target/twInvoiceApi';
import { LANGUAGE_CODE } from 'lib-tw-common';
import { getYearMonth } from '@/utils/date';
import dayjs from 'dayjs';

export default {
  name: 'RecordUsageDetails.vue',
  components: {
    TwHeader,
    TwBreadcrumbs,
    TwSearchField,
    TwButton,
    TwTableSystem,
  },
  data() {
    return {
      schemas: schemas,
      schemasForTargetUserRole: [], // ユーザーロール毎に一覧表示する列が異なるため用意。
      filters: [],
      // テーブル表示のレスポンスデータのコピー
      displayItems: null,
      params: {
        lslConfig: {
          query: {
            limit: 100, // DEFAULT_PAGENATION.LIMITは使用せず、左記の値とする。
            offset: 0
          }
        }
      },
      allCompanies: [], // 全会社のリスト、BFFにて一括で取得。
      selectedCompanyIds: [],
      searchTargetCompaniesForButtonDisplay: null, // 「会社ID/会社名」ボタン表示用ラベル。
      selectionItems: [], // TSVダウンロード用にチェックボックス選択されたレコード（複数）。
      userLanguageSetting: null, // TSVダウンロードの際に利用。
      timeZone: '', // TSVダウンロードの際に利用。
      tsvDownloadApiForTargetUserRole: null, // TSVダウンロードの際に利用。
      keyForQueriesAndFilters: 'RECORD_USAGE_DETAILS',
      totalCount: 0,
      isLoading: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { label: this.$t('BreadCrumbs.Dashboard'), to: '/' },
        { label: this.$t('BreadCrumbs.Usage Details') }
      ];
    },
    isTwAdmin() {
      return this.$store.state.userInformationV2?.twAdminFlg;
    },
    isCompanyAdmin() {
      return this.$store.state.userInformationV2?.companyAdminFlg;
    }
  },
  created() {
    // ユーザーロールによりページへのアクセスのRouting制御を行う。
    if (!this.isTwAdmin && !this.isCompanyAdmin) {
      this.$router.replace({ name: 'Page404' });
      return;
    }

    this.setItemsForListDisplay();
    this.setItemsForTsvDownload();

    // Store/LocalStorageに検索の値が保存されているかをチェック
    // 保存されていれば、applyFiltersでfetchSummariesを呼ぶ
    const queriesFromStorage = this.$store.getters.getQueries(this.keyForQueriesAndFilters);
    if (queriesFromStorage === undefined) this.fetchSummaries();
  },
  async mounted() {
    // 「会社ID/会社名」フィルターはTW管理者のみに表示されるフィルター。
    if (this.isTwAdmin) {
      try {
        await this.fetchAllCompanyName();
  
        // localStorageにcompanyIdsがセットされていた場合
        const selectedCompanyIdsFromLocalStorage = this.$store.getters.getQueries(this.keyForQueriesAndFilters)?.query?.companyIds;
        if (selectedCompanyIdsFromLocalStorage) {
          this.selectedCompanyIds = selectedCompanyIdsFromLocalStorage;
          // 「会社ID/会社名」ボタン表示用ラベルをセットする。
          this.createCompaniesLabelForButtonDisplay(selectedCompanyIdsFromLocalStorage);
        }
      } catch (err) {
        this.$store.dispatch('SHOW_ALERT', err.message);
      }
    }
  },
  methods: {
    // 全会社取得
    async fetchAllCompanyName() {
      const params = {
        lslConfig: {
          serviceCode: 'tw-user-entity-bff-api',
          apiCode: 'get_/v1/companies/initial',
          query: {
            deleteFlg: 0,
            validityStartDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            validityEndDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]')
          }
        }
      };
      
      try {
        const response = await $api.request(params);
        this.allCompanies = response.companies;
      } catch (err) {
        this.$store.dispatch('SHOW_ALERT', err.message);
      }
    },

    // companyIdsから、「会社ID/会社名」ボタン表示用ラベルを作成。
    createCompaniesLabelForButtonDisplay(selectedCompanyIds) {
      if (selectedCompanyIds?.length > 0) {
        const filterSearchTargetCompanies = this.allCompanies?.filter(company =>
          selectedCompanyIds.includes(company.companyId)
        );
        this.searchTargetCompaniesForButtonDisplay = filterSearchTargetCompanies
          .map(company => `(${company.companyId}) / ${company.companyShortName}`)
          .join(", ");
      } else {
        this.searchTargetCompaniesForButtonDisplay = null;
      }
    },

    // 一覧表示の際に利用する値を定義。
    setItemsForListDisplay() {
      this.filters = filters;

      // ユーザーロールにより、検索フィルターと一覧の表示カラムを設定する。
      if (this.isTwAdmin) {
        // (1.) 検索フィルターの設定。
        this.filters = this.filters.filter(item => 
          item.key === "companyIdAndCompanyShortName" ||
          item.key === "yearAndMonth"
        );
  
        // (2.) 一覧の表示カラムを設定。
        this.schemasForTargetUserRole = schemas;
        this.schemasForTargetUserRole = this.schemasForTargetUserRole.filter(item => 
          item.key === "companyId" ||
          item.key === "companyShortName" ||
          item.key === "invoiceYearMonth" ||
          item.key === "chargedFlowsCount"
        );
      }
      if (this.isCompanyAdmin) {
        // (1.) 検索フィルターの設定。
        this.filters = this.filters.filter(item => 
          item.key === "yearAndMonth"
        );
      
        // (2.) 一覧の表示カラムを設定。
        this.schemasForTargetUserRole = schemas;
        this.schemasForTargetUserRole = this.schemasForTargetUserRole.filter(item => 
          item.key === "invoiceYearMonth" ||
          item.key === "chargedFlowsCount"
        );
      }
    },

    // TSVファイルダウンロードの際に利用する値を定義。
    setItemsForTsvDownload() {
      // (1.) ユーザーロールにより、TSVダウンロードのコール対象APIを設定する。
      if (this.isTwAdmin) {
        this.tsvDownloadApiForTargetUserRole = DOWNLOAD_INVOICE_FLOWS_TSV_FOR_TW_ADMIN;
      }
      if (this.isCompanyAdmin) {
        this.tsvDownloadApiForTargetUserRole = DOWNLOAD_INVOICE_FLOWS_TSV_FOR_COMPANY_ADMIN;
      }

      // (2.) ユーザーの言語設定により、userLanguageSettingを設定する。
      this.userLanguageSetting = this.$store.getters.getLanguageKey === 'local' ? LANGUAGE_CODE.JAPANESE : LANGUAGE_CODE.ENGLISH;
  
      // (3.) TSVファイルダウンロードの際に必要となる、ユーザーのtimeZoneを取得。
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    onPaging({ currentPage }) {
      this.params.lslConfig.query.offset = currentPage * this.params.lslConfig.query.limit - this.params.lslConfig.query.limit;
      this.fetchSummaries();
    },

    fetchSummaries() {
      this.isLoading = true;
      const baseParams = {
        lslConfig: {
          query: {
            fromYearMonth: this.params.lslConfig?.query?.fromYearMonth,
            toYearMonth: this.params.lslConfig?.query?.toYearMonth,
            limit: this.params.lslConfig?.query?.limit,
            offset: this.params.lslConfig?.query?.offset
          }
        }
      };
      if (this.isTwAdmin) {
        baseParams.lslConfig.serviceCode = GET_INVOICE_FLOWS_SUMMARIES_FOR_TW_ADMIN.serviceCode;
        baseParams.lslConfig.apiCode = GET_INVOICE_FLOWS_SUMMARIES_FOR_TW_ADMIN.apiCode;
        baseParams.lslConfig.query.companyIds = this.params.lslConfig?.query?.companyIds;

      } else if(this.isCompanyAdmin) {
        baseParams.lslConfig.serviceCode = GET_INVOICE_FLOWS_SUMMARIES_FOR_COMPANY_ADMIN.serviceCode;
        baseParams.lslConfig.apiCode = GET_INVOICE_FLOWS_SUMMARIES_FOR_COMPANY_ADMIN.apiCode;
      }

      $api.request(baseParams)
        .then(response => {
          if (response?.summaries) {
            this.totalCount = response.totalCount;
            this.displayItems = _.cloneDeep(response?.summaries);
            this.displayItems.forEach(item => {
              if (item.invoiceYearMonth) item.invoiceYearMonth = getYearMonth(item.invoiceYearMonth); // 表示用にフォーマット
            });
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 検索を行います
    applyFilters(params) {
      this.params = params;
  
      // Applyされる毎に「会社ID/会社名」ボタン表示用ラベルを更新。
      this.createCompaniesLabelForButtonDisplay(params.lslConfig?.query?.companyIds);

      if (this.totalCount > 0) this.$refs?.table?.clearSelection();
      this.fetchSummaries();
    },

    getRowKey(row) {
      return row.invoiceId;
    },
    // セレクト切り替え
    selectionChange(multipleSelection) {
      this.selectionItems = multipleSelection;
    },

    getFileNameFromHeaders(headerInfo) {
      // ファイル名はResponse Headerの中の'content-disposition'に定義されているため下記をセット。
      let headersFileName = headerInfo['content-disposition'];
      if (headersFileName) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(headersFileName)
        headersFileName = matches[1].replace(/['"]/g, '');
        return headersFileName;
      }
    },

    tsvDownload(selectedRows) {
      const invoiceIds = selectedRows.map(company => company.invoiceId);

      const params = {
        data: {
          invoiceIds: invoiceIds,
          languageCode: this.userLanguageSetting,
          timeZone: this.timeZone
        },
      };
      this.isLoading = true;

      $api
      .download(
        '', 
        this.getFileNameFromHeaders,
        this.tsvDownloadApiForTargetUserRole.apiCode, 
        this.tsvDownloadApiForTargetUserRole.serviceCode, 
        true, 
        undefined, 
        params.data
      )
      .then(() => {
        this.$refs.table?.clearSelection();
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

  }
}
</script>

<style lang='scss' scoped>
::v-deep .search_inner {
    align-items: flex-start;
}
</style>