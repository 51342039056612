<template>
  <div>
    <!-- <div v-if="(mode === 'new' || mode === 'edit') && flow.subordinateInfo && flow.subordinateInfo.canPartialShipmentFlg" class="partial">
      <tw-button type="primary" size="small" :disabled="s.isDirty" @click="partial">Partial</tw-button>
    </div> -->
    <tw-table2
      class="process_table"
      :class="{gray: flow.otherTradingFlg === OTHER_TRADING_FLG.ON || mode === 'editFlow' && !isEditFlowCurrent}"
      :schemas="schemas"
      :items="filteredItems"
      :processTable="true"
      height="auto"
      :mode="mode"
      :current="(mode !== 'editFlow' && s.currentFlow && s.currentFlow.tradingFlowId === tradingFlowId) || isEditFlowCurrent"
      :isDeletableFlow="isDeletableFlow"
      :processList="processList"
      :isOtherTrading="flow.otherTradingFlg === OTHER_TRADING_FLG.ON"
      :tradingFlowId="tradingFlowId"
      :editableTradingFlowIds="editableTradingFlowIds"
      @row-click="processRowClick"
      @row-dbclick="processRowDbClick"
      @select-section="selectSection"
      @select-company-role-change="selectCompanyRole"
      @remove-process="remove"
      @remove-entity="removeEntity"
      @update-process-user-memo="onUpdateProcessUserMemo"
    />
    <el-cascader v-if="mode === 'new' || mode === 'edit' || isEditFlowCurrent" popper-class="category-select" style="margin: 8px 0 21px 60px" v-model="process" placeholder="+ Add Process" :options="filteredProcesses" :props="{value: 'code', label: 'name'}" :filter-method="filter" :disabled="!processAddable || (!s.tradingInfo.tradingPatternInfo || flow.otherTradingFlg === OTHER_TRADING_FLG.ON)" @change="selectProcess" @visible-change="updateOptions" clearable filterable>
      <template slot-scope="{ data }">
        <div style="display: flex;align-items: center">
          <tw-entity-icon v-if="data.entityId" :entityName="data.entityId" size="medium" color="color" style="margin-right: 8px;" />
          <span>{{data.name}}</span>
        </div>
      </template>
    </el-cascader>
    <el-dialog :title="$t('Label.Select Section')" class="header_border"  :visible.sync="dialogSelectSection" :close-on-click-modal="false" width="617px" @close="onCloseDialog" :modal-append-to-body="false">
      <template v-if="dialogSelectSection">
        <h2>{{$t('Label.Select from History')}}</h2>
        <ul>
          <li v-for="section in sectionHistory" :key="section.value" @click="setSectionFromHistory(section)">{{section['en'] || section.label}}</li>
        </ul>
        <tw-mcu-picker :section="section" :options="sections" placeholder="Company/Section" @input="setSection" />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { FROM_TO_TYPE, PROCESS_CANCEL_FLG, PROCESS_PROGRESS_STATUS, DELETE_ABLE_FLG, ENTITY_TYPE, OTHER_TRADING_FLG } from 'lib-tw-common';
import { normalize, sectionForCascader, sectionForPulldown } from '@/utils/searchUtil';
import { entityNames, processNames, } from '@/dictionaries/map.js';
import TwEntityIcon from '@/components/atoms/TwEntityIcon';
import TwMcuPicker from '@/components/molecules/TwMcuPicker';
import TwTable2 from '@/components/molecules/TwTable2';

const MAX_PROCESS_NUMBER = {
  ECREQ: 5,
  ICREQ: 5,
  TPBLI: 2,
  FDCOR: 5,
  FDCOI: 5,
  MIRIP: 3,
};

export default {
  name: 'TwProcessTable',
  inject: ['addProcess', 'setProcess', 'removeProcess', 'getPairFlows', 'setProcessUserMemo', 's'],
  props: {
    schemas: Array,
    items: Array,
    mode: String,
    flowUuid: String,
    tradingFlowId: String,
    flow: Object,
    processList: Array,
    isSavePattern: Boolean,
    index: Number,
    editableTradingFlowIds: Array,
    processAddable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    TwEntityIcon,
    TwMcuPicker,
    TwTable2,
  },
  data() {
    return {
      dialogSelectSection: false,
      section: null,
      from: {},
      to: {},
      process: null,
      categories: _.map(entityNames, o => {
        return {
          ...o,
          name: this.$t(`Entity.${o.nameEn}`)
        };
      }),
      processes: [],
      sections: [],
      sectionDict: [],
      targetProcess: null,
      value: null,
      filteredProcesses: [],
      OTHER_TRADING_FLG,
    }
  },
  computed: {
    sectionHistory() {
      if (!this.targetProcess) {
        return [];
      }
      return _.filter(this.$store.getters.getSectionHistory(this.targetProcess.processId) || [], history => {
        return _.find(this.sectionDict, {sectionId: history.sectionId});
      });
    },
    isDeletableFlow() {
      // 別取引の場合は削除不可
      return this.flow && this.flow.deletableFlg === DELETE_ABLE_FLG.OK && this.flow.otherTradingFlg !== OTHER_TRADING_FLG.ON;
    },
    flatItems() {
      return this.flatProcessTableData(this.items, false);
    },
    filteredItems() {
      return this.flatProcessTableData(this.items, true);
    },
    // lang() {
    //   return this.$store.getters.getLanguageKey;
    // },
    isEditFlowCurrent() {
      return this.mode === 'editFlow' && this.s.editableTradingFlowIds.includes(this.tradingFlowId);
    },
  },
  watch: {
    companyId() {
      this.fetch();
    },
  },
  created() {
    this.processes = _.map(this.categories, o => {
      const children = _.map(_.filter(processNames, {parent: o.entityId}), p => {
        return Object.assign(p, {code: p.processId, name: this.$t(`Process.${p.processType.replace('PT_', '')}`)});
      });
      return Object.assign({children: children}, o);
    });
  },
  methods: {
    processRowClick(row) {
      this.$emit('process-row-click', row);
    },
    processRowDbClick(row) {
      this.$emit('process-row-dbclick', row);
    },
    filter(node, keyword) {
      const keywords = node.pathNodes.reduce((ary, pathNode) => {
        if (Array.isArray(pathNode.data.keywords)) {
          return ary.concat(node.pathLabels).concat(pathNode.data.keywords);
        }
        return ary.concat(node.pathLabels);
      }, []);
      return keywords.some((str) => {return normalize(str).includes(normalize(keyword));});
    },
    // 採番したprocessIdを返却します
    getProcessId(processId) {
      if (!['ECREQ', 'ICREQ', 'TPBLI', 'FDCOR', 'FDCOI', 'MIRIP'].includes(processId)) {
        return processId;
      }
      const filtered = _.filter(this.filteredItems, item => {
        return item.processId.startsWith(processId) && !(item.eventCancel === PROCESS_CANCEL_FLG.ON && [PROCESS_PROGRESS_STATUS.AGREED, PROCESS_PROGRESS_STATUS.DONE].includes(item.processProgressStatusCd));
      });
      let ret;
      _.times(MAX_PROCESS_NUMBER[processId], i => {
        if (!_.find(filtered, {processId: `${processId}0${i + 1}`}) && !ret) {
          ret = `${processId}0${i + 1}`;
        }
      });
      if (!ret) {
        ret = `${processId}01`;
      }
      return ret;
    },
    selectProcess() {
      if (this.process) {
        this.addProcess({entityTypeCd: this.process[0], processId: this.getProcessId(this.process[1]), processType: _.get(processNames[this.process[1]], 'processType'), flowUuid: this.flowUuid, tradingFlowId: this.tradingFlowId}, this.index);
        this.$nextTick(() => {
          this.process = null;
        });
      }
    },
    selectSection(process, fromTo) {
      this.targetProcess = {
        ...process, fromTo, flowUuid: this.flowUuid, tradingFlowId: this.tradingFlowId
      };

      // TODO 取引管理のレスポンスのprocessListからプルダウンを作成
      const query = {
        companyRoleCd: this.isSavePattern ? process[`${fromTo}RoleId`] || null : null,
        entityType: process.entityTypeCd,
        processId: process.processId,
        fromToFlg: fromTo === 'from' ? FROM_TO_TYPE.FROM : FROM_TO_TYPE.TO,
        deleteType: 0,
        // FY2022V2PH6-14 フロー編集時、プロセス追加する際の選択可能な会社がOwner縛りになっている
        // ownerCompanyId: _.get(this.s, 'tradingInfo.tradingPatternInfo.ownerCompanyId')
      };

      // 取引先セクション取得BFF
      this.$store.dispatch('GET_SECTIONS', query)
      .then(res => {
        this.sections = sectionForCascader(res.customerSectionList);
        // console.log(JSON.stringify(this.sections))
        this.sectionDict = sectionForPulldown(res.customerSectionList);
        this.section = [process[`${fromTo}CompanyId`], process[`${fromTo}SectionId`]]
        this.dialogSelectSection = true;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });

    },
    setSection(value) {
      this.value = value;
      this.dialogSelectSection = false;
    },
    onCloseDialog() {
      const value = this.value;

      if (!value) {
        return false;
      }

      let section = {
        tradingId: null,
        tradingName: null,
        tradingShortName: null,
        sectionId: null,
        sectionName: null,
        sectionShortName: null,
      };

      if (value && value[1]) {
        const targetSection = _.find(this.sectionDict, {value: value[1]});
        if (targetSection) {
          this.$store.commit('SET_SECTION_HISTORY', {processId: this.targetProcess.processId, section: targetSection});
          section = targetSection;
        }
      }

      this.setProcess(section, this.targetProcess, this.index);
      this.$nextTick(() => {
        this.section = null;
        this.targetProcess = null;
        this.value = null;
        this.sectionDict = [];
        this.sections= [];
      });
    },
    setSectionFromHistory(section) {
      this.value = [section.tradingId, section.sectionId];
      this.dialogSelectSection = false;
    },
    remove(process) {
      this.removeProcess({
        ...process, flowUuid: this.flowUuid, tradingFlowId: this.tradingFlowId
      }, this.index);
    },
    removeEntity(process) {
      this.$emit('remove-entity', process)
    },
    // プロセス追加プルダウンの中身を生成します
    getFilteredProcesses() {
      // 同一プロセスの削除申請がDONEされたらプロセス追加可能
      const items = _.reject(this.flatItems, o => {
        return o.eventCancel === PROCESS_CANCEL_FLG.ON && [PROCESS_PROGRESS_STATUS.AGREED, PROCESS_PROGRESS_STATUS.DONE].includes(o.processProgressStatusCd);
      });

      const entities = _.map(this.processes, o => {
        return {
          ...o,
          children: _.reject(o.children, p => {
            if (!_.find(this.s.processList, { processType: p.processType })) {
              return true;
            }
            if (!['ECREQ', 'ICREQ', 'TPBLI', 'FDCOR', 'FDCOI', 'MIRIP'].includes(p.processId)) {
              return _.find(items, { processId: p.processId });
            }
            return _.filter(items, { processType: p.processType }).length >= MAX_PROCESS_NUMBER[p.processId];
          })
        };
      });

      // フローの追加不可エンティティリストに含まれるか、子プロセスがないエンティティは削除。三国間取引で輸送契約エンティティが登録されている場合も削除
      const ret = _.reject(entities, o => {
        return this.flow.unAddableArea.includes(o.code) || _.isEmpty(o.children) || this.shouldRejectTransportationAgreement(o);
      });

      return ret;
    },
    updateOptions() {
      this.filteredProcesses = this.getFilteredProcesses();
    },
    partial() {
      this.s.showPartial(this.tradingFlowId);
    },
    getEntityName(entityTypeCd) {
      return this.$t(`Entity.${_.get(_.find(entityNames, {code: entityTypeCd}), 'nameEn')}`);
    },
    // 取引管理のプロセスリストを生成
    // eslint-disable-next-line no-unused-vars
    flatProcessTableData(entityList, needReject) {
      // if (needReject) {
      //   entityList = _.map(entityList, (o) => {
      //     return {
      //       ...o,
      //       processStatus: _.reject(o.processStatus, p => {
      //         return p.visibleFlg === VISIBLE_FLG.OFF;
      //       })
      //     };
      //   });
      // }

      const lastEntityTypeCd = _.get(_.last(entityList), 'entityTypeCd');

      const ret = _.reduce(entityList, (res, o) => {
        res = res.concat(_.map(o.processStatus, (process, index) => {
          return {..._.omit(o, ['processStatus']), ...process, entityName: index ? '' : this.getEntityName(o.entityTypeCd), noLine: index && o.entityTypeCd === lastEntityTypeCd};
        }));
        return res;
      }, []);
      return ret;
    },
    // Save Pattern カンパニーロール変更
    selectCompanyRole({row, key}) {
      this.$emit('select-company-role-change', {row, key});
    },
    // 三国間取引で輸送契約エンティティが追加不可かどうかを返却します
    shouldRejectTransportationAgreement(entity) {
      if (entity.code !== ENTITY_TYPE.TRANSPORTATION_AG) {
        return false;
      }
      const flows = this.getPairFlows(this.flow);

      if (_.some(flows, flow => {
        return _.some(flow.entityStatus, o => {
          return o.entityTypeCd === ENTITY_TYPE.TRANSPORTATION_AG;
        });
      })) {
        return true;
      }
      return false;
    },
    // PB-271 
    // TwTable2.vue にて、プロセスメモの入力フォームが更新されるたびにイベントを受け取り、
    // inject された setProcessUserMemo を実行して値を更新する。
    onUpdateProcessUserMemo(process) {
      this.setProcessUserMemo({
        ...process, flowUuid: this.flowUuid, tradingFlowId: this.tradingFlowId
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .partial {
    padding: 9px 16px;
  }

  ul, li {
    list-style: none;
  }

  ul {
    border-bottom: 1px solid $color_gray_300;
    margin-bottom: 24px;
    padding: 0;
  }

  li {
    margin: 0 0 24px;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    color: $color_dark_blue;
    cursor: pointer;
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px;
  }
</style>
