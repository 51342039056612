<template>
  <el-select v-model="localValue" class="section" :class="{single_selected: singleSelect, multiple_selected: multipleSelect, is_focus: isFocus}" popper-class="custom-section" placeholder="Select" :size="size" :reserve-keyword="true" multiple collapse-tags clearable filterable @change="onChange">
    <el-option
      v-for="section in sections"
      :key="section.value"
      :label="section.label"
      :value="section.value">
    </el-option>
  </el-select>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TwSelectSection',
  props: {
    value: Array,
    sections: Array,
    size: String,
  },
  data() {
    return {
      observer: null,
      isFocus: false,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    singleSelect() {
      return _.isArray(this.localValue) && this.localValue.length === 1;
    },
    multipleSelect() {
      return _.isArray(this.localValue) && this.localValue.length > 1;
    },
  },
  mounted() {
    // is-focusクラスを監視してel-selectに反映
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.target.classList.contains('is-focus')) {
          this.isFocus = true;
        } else {
          this.isFocus = false;
        }
      })
    });

    this.$nextTick(() => {
      const el = this.$el.querySelector('.el-input.el-input--suffix');
      this.observer.observe(el, { attributes : true, attributeFilter: ['class'], });
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onChange(val) {
      this.$emit('change', val);
    },
  }
};
</script>

<style lang="scss">
// FY2022V2-72 Section欄の文字入力、サジェスト対応について
.search_unit.compact {
  .el-select.section:not(.is_focus) .el-select__input {
    min-width: 0;
    width: 0!important;
    height: 0;
    margin-left: 0;
  }

  .el-select.section:not(.multiple_selected) .el-input.el-input--suffix:not(.is-focus), .el-select.section:not(.multiple_selected) .el-input.el-input--suffix:not(.is-focus) {
    height: 40px!important;
  }

  .el-select.section.multiple_selected.is_focus .el-select__input, .el-select.section.single_selected.is_focus .el-select__input {
    min-width: 50px!important;
    margin-left: 8px;
  }
}

.tw_dashboard_calendar {
  .el-select.section:not(.is_focus) .el-select__input {
    min-width: 0;
    width: 0!important;
    height: 0;
    margin-left: 0;
  }

  .el-select.section:not(.multiple_selected) .el-input.el-input--suffix:not(.is-focus), .el-select.section:not(.multiple_selected) .el-input.el-input--suffix:not(.is-focus) {
    height: 28px!important;
  }

  .el-select.section.multiple_selected.is_focus .el-select__input, .el-select.section.single_selected.is_focus .el-select__input {
    min-width: 30px!important;
    margin-left: 8px;
  }
}
.el-select-dropdown.el-popper.is-multiple.custom-section {
  max-width: 30%;
  & .el-select-dropdown__item {
    display: block;
    white-space: pre-wrap;
    height: auto;
    & span {
      display: inline-block;
      line-height: 1.1;
    }
  }
}
</style>
