<template>
  <div class='tw_container_header' :class="{disabled: disabled, 'swiper-no-swiping': disabled}">
    <div class='inner'>
      <template v-if="!isApprover">
        <div>
          <router-link  class='container not_accepted' to="/not-accepted">
            <span>Not Accepted</span>
            <span v-if="!hideCount" class='number'>{{ summary.processesCountNotAccepted }}</span>
          </router-link>
        </div>

        <div>
          <router-link class='container confirmation' to="/confirmation-to">
            <span>Confirmation<span style='display: inline-block'>(To)</span></span>
            <span v-if="!hideCount" class='number'>{{ summary.processesCountConfirmationTo }}</span>
          </router-link>
        </div>
      </template>

      <div v-if='isApprover || isCompanyAdmin || isTwAdmin'>
        <router-link class='container approval_request'
                     :to="{name: 'Approval'}"
        >
          <span>Approval Request</span>
          <span v-if="!hideCount" class='number'>{{ summary.processesCountApprovalRequest }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { $api } from '@/store/ApiClient';

export default {
  name: 'TwContainerHeader',
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
          draggable: true,
        },
        grabCursor: true,
      },
    }
  },
  computed: {
    summary() {
      return this.$store.state.dashBoardSummary || {};
    },
    isApprover() {
      return this.$store.state.userInformationV2?.approverFlg
    },
    isPic() {
     return this.$store.state.userInformationV2?.picFlg
    },
    isCompanyAdmin() {
      return this.$store.state.userInformationV2?.companyAdminFlg
    },
    isTwAdmin() {
      return this.$store.state.userInformationV2?.twAdminFlg;
    },
    hideCount() {
      return this.isCompanyAdmin || this.isTwAdmin;
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      // bff_db_7 ダッシュボードプロセス件数一括取得
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/dashboard/processes-counts',
          query: {
            clientDate: dayjs().format('YYYY-MM-DD'),
          }
        }
      };

      $api
      .request(params)
      .then(res => {
        this.$store.commit('SET_DASHBOARD_SUMMARY', res);
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_container_header {
  margin-bottom: 8px;

  &.disabled {
    pointer-events: none;
    cursor: default!important;

    .container:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color_black, 0.3);
      content: '';
    }
  }

  .inner {
    display: flex;
    gap: 20px;
    width: auto;
    height: 120px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container {
    position: relative;
    text-decoration: none;
    display: flex;
    width: 238px;
    height: 120px;
    padding: 18px;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    transition: transform .3s;

    &:hover, &:focus {
      @include card_shadow;
    }

    &:not(:has(.number)) {
      justify-content: center;
      font-size: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
    &.not_accepted {
      background: url(/images/container01.png) center;
    }
    &.approval_request {
      background: url(/images/container02.png) center;
    }
    &.confirmation {
      background: url(/images/container03.png) center;
    }

    .number {
      font-size: 72px;
      flex-shrink: 0;
    }
  }
}
</style>
