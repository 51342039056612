<template>
  <div class="relative" :class="{'is-loading': !items && downloadStatus}">
    <template v-if="items && items.length">
      <tw-pagination v-show="!nopager" :total="totalCount || items.length" :currentPage="currentPage" :pageSize="pageSize" @onPaging="onPaging" />

      <div v-loading="isLoading" class="tw_table_system_wrapper">
        <tw-table
          :schemas="schemas"
          :items="filteredItems"
          :selection="selection"
          :expand="expand"
          :border="border"
          :sortProp="sortProp"
          :sortOrder="sortOrder"
          :multipleSelection="multipleSelection"
          :rowKey="rowKey"
          :height="height"
          :processSchemas="processSchemas"
          :mode="mode"
          :highlight-current-row="highlightCurrentRow"
          :tradingId="tradingId"
          :hasError="hasError"
          :is-open="isOpen"
          :defaultExpandAll="defaultExpandAll"
          :expand-row-keys="expandRowKeys"
          :flowNameRule="flowNameRule"
          :actions="actions"
          :actionsMenu='actionsMenu'
          :editableTradingFlowIds="editableTradingFlowIds"
          :isPureTextFlowName="isPureTextFlowName"
          :processAddable="processAddable"
          @current-change="handleCurrentChange"
          @sort-change="sortChange"
          @selection-change="selectionChange"
          @row-click="rowClick"
          @row-dbclick="rowDbClick"
          @cell-mouse-enter='cellMouseEnter'
          @cell-mouse-leave='cellMouseLeave'
          @process-row-click="processRowClick"
          @process-row-dbclick="processRowDbClick"
          @remove-flow="removeFlow"
          @show-flow-detail="showFlowDetail"
          @select-file="selectFile"
          @attach-download="attachDownload"
          @link-click="linkClick"
          @news-edit-click="newsEditClick"
          @dirty="$emit('dirty')"
          @onClickOperation="this.$emit('onClickOperation', index)"
          @other-trading-flow-click="onOtherTradingFlowClick"
          @expand-key-state="$emit('expand-key-state-system', $event)"
          @change-column-width="$emit('change-column-width', $event)"
          ref="table"
        />
      </div>
      <tw-pagination v-show="!nopager" :total="totalCount || items.length" :currentPage="currentPage" :pageSize="pageSize" @onPaging="onPaging" />
    </template>
    <div v-else-if="items" class="empty_message">
      <span v-if="isNoFlow">{{$t('Message.Please add flow')}}</span>
      <span v-else-if="!hideEmptyMessage && isInitialSearch">{{$t('Message.NoResult2')}}</span>
      <span v-else-if="!hideEmptyMessage && !isInitialSearch">{{$t('Message.NoResult1')}}</span>
    </div>
    <div v-else class="el_table tw_table" style="background: white;margin: 12px 0;padding: 40px;">
      <el-skeleton :rows="5" animated />
    </div>
    <div v-show="downloadStatus" class="download-status">{{ downloadStatus }}</div>
  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_PROGRESS_STATUS, SORT_TYPE } from 'lib-tw-common';
import TwPagination from '../molecules/TwPagination.vue';
import TwTable from '../molecules/TwTable.vue';

const PROCESS_PROGRESS_STATUS_ORDER = {
  NOT_STARTED: 0,
  F_INPUT_IN_PROGRESS: 1,
  F_AWAITING_APPROVAL: 2,
  T_AWAITING_CONFIRMATION: 3,
  T_INPUT_IN_PROGRESS: 1,
  T_AWAITING_APPROVAL: 5,
  AGREED: 6,
  DONE: 6,
};

const getProgressStatusOrder = statusCd => {
  return PROCESS_PROGRESS_STATUS_ORDER[_.findKey(PROCESS_PROGRESS_STATUS, (value) => {
    return value === statusCd;
  })];
};


export default {
  name: 'TwTableSystem',
  components: {
    TwPagination,
    TwTable
  },
  props: {
    schemas: Array,
    items: Array,
    border: {
      type: Boolean,
      required: false,
      default: false
    },
    pageSize: Number,
    selection: Boolean,
    expand: Boolean,
    rowKey: [String, Function],
    height: [String, Number],
    nopager: Boolean,
    processSchemas: [Object, Array],
    mode: String,
    highlightCurrentRow: Boolean,
    tradingId: String,
    hasError: Array,
    hideEmptyMessage: Boolean,
    isOpen: Boolean,
    serverPagination: Boolean,
    totalCount: Number,
    offset: Number,
    defaultExpandAll: Boolean,
    expandRowKeys: Array,
    isInitialSearch: Number,
    flowNameRule: Array,
    actions: {
      type: Array,
      required: false
    },
    actionsMenu: {
      type: Array,
      required: false
    },
    editableTradingFlowIds: Array,
    isLoading: Boolean,
    downloadStatus: String,
    isPureTextFlowName: {
      type: Boolean,
      required: false
    },
    processAddable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      sortProp: null,
      sortOrder: null,
      sortColumn: null,
      multipleSelection: [],
      currentPage: 1,
    };
  },
  computed: {
    filteredItems() {
      if (this.serverPagination) {
        return this.items;
      }
      const to = this.currentPage * this.pageSize;
      const from = to - this.pageSize;
      let sortProp = this.sortProp;
      if (['from', 'to'].includes(this.sortProp)) {
        if (_.get(this.sortColumn, 'type') === 'UserMail') {
          sortProp = 'userNameFrom';
        } else {
          sortProp = `${this.sortProp}.${this.sortProp}CompanyShortName`;
        }
      } else if (this.sortProp === 'owner') {
        sortProp = 'ownerCompanyShortName';
      } else if (['approve', 'applicant'].includes(this.sortProp)) {
        sortProp = `${this.sortProp}UserName`;
      } else if (this.sortProp === 'update' && _.get(this.sortColumn, 'type') === 'User') {
        sortProp = 'updateUserName';
      }

      let items;
      if (sortProp && sortProp.includes('processProgressStatus')) {
        items = this.sortOrder ? _.orderBy(this.items, [(o) =>  { return getProgressStatusOrder(o[sortProp])}, 'id'], [this.sortOrder, 'asc']) : this.items;
      } else {
        items = this.sortOrder ? _.orderBy(this.items, [sortProp, 'id'], [this.sortOrder, 'asc']) : this.items;
      }
      if (this.nopager) {
        return items;
      }
      return _.filter(items, (o, i) => {
        return _.inRange(i, from, to);
      });
    },
    isNoFlow() {
      return ['NewTrading', 'SavedTrading', 'ManageTrading'].includes(this.$route.name) && this.flowNameRule;
    },
  },
  watch: {
    items() {
      if (this.serverPagination) {
        return;
      }
      this.currentPage = 1;
    },
    offset(val) {
      if (val === 0) {
        this.currentPage = 1;
      }
    }
  },
  methods: {
    deleteRow(index, rows) {
      this.$confirm(`${rows[index].id}を削除します。<br>よろしいですか？`, '', {
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          rows.splice(index, 1);
        })
        .catch(() => {});
    },
    emitPaging() {
      if (this.serverPagination) {
        // let sortProp = this.sortProp;
        // if (['from', 'to'].includes(this.sortProp)) {
        //   sortProp = `${this.sortProp}CompanyName`;
        // } else if (this.sortProp === 'owner') {
        //   sortProp = 'ownerCompanyName';
        // } else if (['approve', 'applicant'].includes(this.sortProp)) {
        //   sortProp = `${this.sortProp}UserName`;
        // }

        this.$emit('paging', {target: this.sortOrder ? this.sortProp : null, sortType: this.sortOrder ? SORT_TYPE[this.sortOrder.toUpperCase()] : null, currentPage: this.currentPage});
      }
    },
    sortChange(column) {
      if (this.sortProp === column.property) {
        this.sortOrder = this.sortOrder === null ? 'asc' : this.sortOrder === 'asc' ? 'desc' : null;
      } else {
        this.sortProp = column.property;
        this.sortOrder = 'asc';
      }
      this.sortColumn = this.sortOrder ? column : null;
      this.emitPaging();
    },
    selectionChange(multipleSelection) {
      this.$emit('selection-change', multipleSelection);
    },
    onPaging(val) {
      this.currentPage = val;
      this.emitPaging();
    },
    rowClick(row, column, event) {
      this.$emit('row-click', row, column, event);
    },
    rowDbClick(row) {
      this.$emit('row-dbclick', row);
    },
    cellMouseEnter(row, column, cell, event) {
      this.$emit('cell-mouse-enter', row, column, cell, event);
    },
    cellMouseLeave(row, column, cell, event) {
      this.$emit('cell-mouse-leave', row, column, cell, event);
    },
    processRowClick(row) {
      this.$emit('process-row-click', row);
    },
    processRowDbClick(row) {
      this.$emit('process-row-dbclick', row);
    },
    removeFlow(row) {
      this.$emit('remove-flow', row);
    },
    showFlowDetail(row) {
      this.$emit('show-flow-detail', row);
    },
    handleCurrentChange(row, oldRow) {
      this.$emit('current-change', row, oldRow);
    },
    // 商品カタログダウンロード
    selectFile(file) {
      this.$emit('select-file', file);
    },
    // 添付ファイル一覧個別ダウンロード
    attachDownload(row) {
      this.$emit('attach-download', row);
    },
    linkClick(row) {
      this.$emit('link-click', row);
    },
    // お知らせ編集クリック
    newsEditClick(informationId) {
      this.$emit('news-edit-click', informationId);
    },
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    toggleRowSelection(row, selected) {
      this.$refs.table.toggleRowSelection(row, selected);
    },
    // rowの選択状態をセットします
    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    onOtherTradingFlowClick(row) {
      this.$emit('other-trading-flow-click', row);
    },
  }
};
</script>

<style lang="scss" scoped>
div.relative {
    position: relative;
  .download-status {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 32px;
    font-size: 12px;
    color: #5E5873;
    text-align: right;
    font-weight: bold;
    color: #5185C5;
    background: #ffffff;
    border-radius: 6px;
    padding: 0 8px;
  }
}
.is-loading {
  padding-top: 32px;
}
.empty_message {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: $color_black;
  margin-top: 33px;
}
</style>
<style lang="scss">
.tw_table_system_wrapper {
  .el-loading-mask {
    background:  rgba($color_white, 0.6);
    border-radius: 8px;
  }
}
</style>
