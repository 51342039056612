<template>
  <div class="tw_approval_list permit_history">
    <tw-collapse :title="`${s.processType === PROCESS_TYPE.ECPERMIT ? 'Export' : 'Import'} Customs Permit`" :initialOpen="items && items.length > 0">
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.updateUserName" class="column">
              <div class="user">
                <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{item.updateUserName}}</div>
                  <div class="from_name">{{item.updateUserName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="date">{{item.updateDate | dateTimeFormat}}</div>
                <div class="action" :class="{ linking: checkLatest(item) && item.permitLinkFlg === PERMIT_LINK_FLG.ON }" @click="openDrawer(item)">
                  Declaration No {{item.declarationNo}}
                  <template v-if="checkLatest(item) && item.permitLinkFlg === PERMIT_LINK_FLG.ON">
                    <img v-show="item.attachmentFile && item.attachmentFile.documentManageId" src="@/assets/images/icons/icon_attach_file.svg" class="pdf">
                    <img src="@/assets/images/icons/link_chain_on.svg">
                  </template>
                  <template v-else-if="item.permitLinkFlg === PERMIT_LINK_FLG.ON">
                    <img v-show="item.attachmentFile && item.attachmentFile.documentManageId" src="@/assets/images/icons/icon_attach_file.svg" class="pdf">
                    <img src="@/assets/images/icons/link_chain_on_gray.svg">
                  </template>
                  <template v-else-if="item.permitLinkFlg === PERMIT_LINK_FLG.OFF">
                    <img v-show="item.attachmentFile && item.attachmentFile.documentManageId" src="@/assets/images/icons/icon_attach_file_off.svg" class="pdf">
                    <img src="@/assets/images/icons/link_chain_off.svg">
                  </template>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>

    <el-drawer :visible.sync="drawerShow" size="auto" :modal="false" :withHeader="false">
      <tw-permit-detail-inner v-if="drawerShow" :isPermitHistory="true" @close="close" :processType="s.processType" :declarationNo="item.declarationNo" :declarationDate="item.declarationDate" :trx="item.declarationTrx" :tradingId="tradingId" />
      <div v-if="drawerShow" class="circle" @click="close" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwPermitDetailInner from '@/components/templates/TwPermitDetailInner';
import { PROCESS_TYPE, PERMIT_LINK_FLG } from 'lib-tw-common';

export default {
  name: 'TwPermitHistory',
  components: {
    TwCollapse,
    TwPermitDetailInner,
  },
  inject: ['s'],
  data() {
    return {
      drawerShow: false,
      drawerItem: {},
      item: {},
      PERMIT_LINK_FLG: PERMIT_LINK_FLG,
      PROCESS_TYPE: PROCESS_TYPE,
    };
  },
  computed: {
    items() {
      return _.get(this, 's.res.permitHistory') || [];
    },
    permitLatest() {
      return _.get(this, 's.res.permitLatest') || [];
    },
    tradingId() {
      return _.get(this, 's.res.tradeManagement.tradingId') || '';
    },
  },
  created() {},
  methods: {
    openDrawer(item) {
      if (!this.checkLatest(item) || item.permitLinkFlg === PERMIT_LINK_FLG.OFF) return
      this.item = item;
      this.drawerShow = true;
    },
    close() {
      this.drawerShow = false;
    },
    // 最新の紐付けに含まれるかどうかの判定
    checkLatest(item) {
      if(!this.permitLatest) return false;
      return ['declarationNo', 'declarationTrx', 'declarationDate'].every(keyForUnique => item[keyForUnique] === this.permitLatest[keyForUnique]);
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    justify-content: space-between;
    .column {
      width: 100%;
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .between {
      display: flex;
      justify-content: space-between;
    }
    .date, .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .action {
      display: flex;
      align-items: center;
      &.linking {
        color: $color_dark_blue;
        cursor: pointer;
      }
      >img {
        margin-left: 8px;
        &.pdf {
          width: 20px;
        }
      }
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
::v-deep .el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);
  .el-drawer__body {
    width: auto;
    width: 990px;
    >.permit_detail_inner {
      padding: 30px 40px 30px 35px;
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;
    &:hover {
      opacity: .5;
    }
  }
}
</style>
