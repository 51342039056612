<template src="./ProcessDetail.html"></template>

<script>
import _ from 'lodash';
import { ABLE_FLG, PROCESS_ID, PROCESS_TYPE, PROCESS_OPERATION, RESPONSIBLE_FLG, BOOKING_CANCEL_FLG } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinProcessDetail from '@/utils/mixinProcessDetail.js';
import schemas from '@/dictionaries/processes/tpatprb.json';

export default {
  name: 'ProcessDetail',
  mixins: [mixinProcessDetail],
  data() {
    return {
      processId: PROCESS_ID.TPARB,
      processType: PROCESS_TYPE.TPATPRB,
      tradeManagement: null,
      schemas: schemas,
      rules: this.createRules(schemas.tables),
    };
  },
  methods: {
    fetch(updateCommentOnly = false) {
      // bff_tp_20 Booking依頼プロセス詳細初期表示BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/request-booking/{entityId}/{processId}/{processSeq}/initial',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          },
          query: {
            processTrx: this.versionNo,
          }
        },
        tag: this.pushCancelTag(),
      };

      $api
        .request(params)
        .then(res => {
          if (!this.checkEditPermission(res)) {
            return;
          }
          if(updateCommentOnly) {
            this.res.chats = res.chats;
          } else {
            if (!this.isArchive && res.tradeManagement.mailChatDocumentAbleFlg === ABLE_FLG.TRUE && !this.communicationDestinations.from) {
              this.getCommunicationDestinations();
            }
            this.res = res;
            // linkageGoodsTypeのレスポンスの階層をリクエストに合わせる
            this.res.goodsTypeMasterListForResponse = _.get(this.res, 'linkageGoodsLineType.goodsTypeMasterListForResponse') || [];
            this.res.linkageGoodsLineType = _.get(this.res, 'linkageGoodsLineType.goodsType') || [];
            // BookingステータスのcanCancelフラグがfalseの場合は、bookingCancelFlgを強制的にOFFに設定する
            const canCancel = _.get(this, 'res.bookingLatest.canCancel');
            let bookingCancelFlg = _.get(this, 'res.bookingCancelFlg');
            if (!canCancel && bookingCancelFlg === BOOKING_CANCEL_FLG.ON) {
              // Bookingキャンセルできない場合にキャンセルフラグがONで残っていたらOFFに設定
              this.res.bookingCancelFlg = BOOKING_CANCEL_FLG.OFF;
            }
            this.cloneItems = this.cloneDeep(this.res);
            this.roles = {
              from: res.from,
              to: res.to,
              owner: res.owner
            };
            requestAnimationFrame(() => {
              this.tradeManagement = this.res.tradeManagement || {};
            });
          }
          if (_.last(this.res.chats)) {
            this.readComment(_.last(this.res.chats).chatManageId);
          }
        })
        .catch(err => {
          if (err.isCanceled) {
            return;
          }
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    async approvalRequest(action, documents, overwriting = false) {
      this.$store.commit('START_PROCESS');
      // 商品のreferenceNoGrpをセット
      this.setReferenceNoGrp();
      const isDeleteRequest = this.tradeManagement.eventCancel || action === PROCESS_OPERATION.T_SAVING_DEL;
      // 削除申請時は項目データを送信しない、バリデーションをしない
      if (isDeleteRequest || await this.checkValidate()) {
        // bff_tp_23 Booking依頼承認依頼BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/request-booking/{entityId}/{processId}/{processSeq}',
            path: {
              entityId: this.entityId,
              processId: this.processId,
              processSeq: parseInt(this.processSeq)
            }
          },
          data: isDeleteRequest ? {
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
          } : _.omit({
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
            ...this.rejectHiddenGroups(),
            documents: documents,
          }, ['emailTransmissionHistories', 'chats', 'tradeManagement', 'requestApproveHisTrx', 'updateHistory'])
        };

        $api
          .request(params)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            if (this.$route.name.includes('EditProcess')) {
              this.$router.replace({
                name: `Process${_.capitalize(this.processId.replace(/\d/g, ''))}`,
                params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() }
              });
            }
            this.fetch();
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            if (this.isConflict(err)) {
              this.$store.dispatch('SHOW_CONFIRM', err.message)
              .then(() => {
                this.approvalRequest(action, documents, true);
              })
              .catch(() => {});
            } else {
              this.$store.dispatch('SHOW_ALERT', err.message);
            }
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    async save(action, documents, overwriting = false) {
      this.$store.commit('START_PROCESS');
      // 下書き仕様ののバリデーションにする
      const normalRules = this.createRules(schemas.tables, false);
      this.rules = this.createRules(schemas.tables, true);
      this.draftValid = true;

      if (await this.checkValidate()) {
        // bff_tp_21 Booking依頼下書き保存BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/request-booking/{entityId}/{processId}/{processSeq}/wk',
            path: {
              entityId: this.entityId,
              processId: this.processId,
              processSeq: parseInt(this.processSeq)
            }
          },
          data: _.omit({
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
            ...this.rejectHiddenGroups(),
            documents: documents,
          }, ['emailTransmissionHistories', 'chats', 'tradeManagement', 'requestApproveHisTrx', 'updateHistory'])
        };

        $api
          .request(params)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            this.$router.replace({
              name: `Process${_.capitalize(this.processId.replace(/\d/g, ''))}`,
              params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() }
            });
            // 通常仕様のバリデーションに戻す
            this.rules = normalRules;
            this.draftValid = false;
            this.fetch();
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            if (this.isConflict(err)) {
              this.$store.dispatch('SHOW_CONFIRM', err.message)
              .then(() => {
                // 通常仕様のバリデーションに戻す
                this.rules = normalRules;
                this.draftValid = false;
                this.save(action, documents, true);
              })
              .catch(() => {
                // 通常仕様のバリデーションに戻す
                this.rules = normalRules;
                this.draftValid = false;
              });
            } else {
              this.$store.dispatch('SHOW_ALERT', err.message);
              // 通常仕様のバリデーションに戻す
              this.rules = normalRules;
              this.draftValid = false;
            }
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        // 通常仕様のバリデーションに戻す
        this.rules = normalRules;
        this.draftValid = false;
      }
    },
    approve(action, comment, to = false) {
      this.$store.commit('START_PROCESS');
      // bff_tp_24 Booking依頼承認BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/request-booking/{entityId}/{processId}/{processSeq}/approve',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          action: action,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(async() => {
            this.$store.dispatch('SHOW_COMPLETED');
            if (to) {
              await new Promise((resolve) => setTimeout(resolve, 500));
              this.$router.push(to);
            }
          }, 500);
          if (!to) this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    withdraw(action, comment, to = false) {
      this.$store.commit('START_PROCESS');
      // bff_tp_25 Booking依頼差戻・引戻・再確認依頼BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/request-booking/{entityId}/{processId}/{processSeq}/re',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          action: action,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(async() => {
            this.$store.dispatch('SHOW_COMPLETED');
            if (to) {
              await new Promise((resolve) => setTimeout(resolve, 500));
              this.$router.push(to);
            }
          }, 500);
          if (!to) this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    deleteDraft(comment) {
      this.$store.commit('START_PROCESS');
      // bff_tp_22 Booking依頼削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/request-booking/{entityId}/{processId}/{processSeq}/delete',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(() => {
            this.$store.dispatch('SHOW_COMPLETED');
          }, 500);
          this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    register(action, documents) {
      action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_APPROVE_REQ : PROCESS_OPERATION.T_APPROVE_REQ;
      this.approvalRequest(action, documents);
    },
    async checkValidate() {
      const lkResult = await this.validationCheck('form');
      const tsResult = await this.tsValidationCheck(this.$refs.form);
      return tsResult && lkResult;
    },
  }
};
</script>

<style lang="scss" scoped src="./ProcessDetail.scss"></style>
