<template>
  <div class="tw_upload_drawer permit">
    <el-drawer class="drawer" :visible.sync="drawerShow" :withHeader="false" size="1082px" @close="close">
      <tw-collapse :title="$t('Label.Permit File Upload')" :initial-open="true">
        <div class="attachment" @dragover.prevent="dragEnter" @dragleave.prevent="dragLeave" @drop.prevent="dropFile" :class="{enter: dragin}" multiple>
          <!-- <input ref="dropInput" class="drop_input" @change="setFile" type="file" multiple accept=".txt"> -->
          <div class="buttons">
            <tw-button type="secondary" size="medium" class="choose_file" @click="loadFiles">Choose Local Files<input ref="filesInput" class="file_input" @change="setFile" type="file" multiple accept=".txt,.pdf"></tw-button>
            <tw-button type="secondary" size="medium" class="choose_file" @click="loadDirectory">Choose Local Directory<input ref="directoryInput" class="file_input" @change="setFile" type="file" webkitdirectory directory></tw-button>
          </div>
          <div class="droparea">
            <img src="@/assets/images/dropfile.svg">
            Drop files or directory here to upload.
          </div>
          <div class="attach">
            <div>{{$t('Label.File description')}}</div>
          </div>
        </div>
        <div class="circle" @click="close" />
      </tw-collapse>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible" @close="cancelProcess" append-to-body class="check-file-dialog">
      <div class="modal-content">
        <p class="dialog-message">
          会社マスタに登録されているNACCSコードと一致しないファイルが含まれています。<br>
          許可書プールに登録するファイルを選択し[OK]をクリックしてください。
        </p>
        <div class="scroll-content">
          <div v-for="file in modalFiles" :key="file.declarationNo" class="file-content">
            <label class="modal-file-label">
              <el-checkbox v-model="file.selected" class="file-checkbox"></el-checkbox>
              <div v-if="file.permitInfo === 'D'" class="file-details">
                <p>{{ file.fileName }}</p>
                <p>輸入者コード: {{ file.consigneeId }}  輸入者名: {{ file.consigneeName }}</p>
                <p>輸入取引者コード: {{ file.buyerId }} 輸入取引者名: {{ file.buyerName }}</p>
              </div>
              <div v-if="file.permitInfo === 'E'" class="file-details">
                <p>{{ file.fileName }}</p>
                <p>輸出者コード: {{ file.shipperId }}  輸出者名: {{ file.shipperName }}</p>
              </div>
            </label>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <tw-button type="primary" @click="okButtonProcess" class="ok-button">OK</tw-button>
        <tw-button @click="cancelProcess" class="cancel-button">Cancel</tw-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import TwButton from '@/components/atoms/TwButton';
import TwCollapse from '@/components/molecules/TwCollapse';
import { $api } from '@/store/ApiClient';
import { PROCESS_TYPE } from 'lib-tw-common';

import { decodeBuffer } from '@/utils/tsv';
// 除外するファイルのリスト
const EXCLUDES = ['.DS_Store'];

export default {
  name: 'TwPermitUpload',
  components: {
    TwButton,
    TwCollapse,
  },
  props: {
    processType: String,
    isShowUpload: Boolean,
  },
  data() {
    return {
      PROCESS_TYPE: PROCESS_TYPE,
      drawerShow: false,
      items: null,
      fileList: [], // 取得したファイルを格納するリスト
      dragin: false,
      documentsForm: {
        documents: [], // 最終的にBFFに渡すリスト
      },
      dialogVisible: false,
      modalFiles: []
    };
  },
  computed: {
    isFwd() {
      return this.$store.getters.isFwd;
    },
  },
  created() {},
  watch: {
    isShowUpload(val) {
      this.drawerShow = val;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    end() {
      this.close();
      this.$store.commit('END_PROCESS');
    },
    // アップロード処理
    loadFiles() {
      this.$refs.filesInput.click();
    },
    loadDirectory() {
      this.$refs.directoryInput.click();
    },
    async setFile(e) {
      this.$store.commit('START_PROCESS');
      // ファイルを取得して、fileList[]に格納
      this.fileList = [];
      this.documentsForm.documents = [];
      if (_.get(e, 'target.files[0]')) {
        // ファイル選択（input）の場合の処理
        const files = e.target.files;
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else if (_.get(e, 'dataTransfer.files') && _.get(e, 'dataTransfer.files').length > 1) {
        // ドラッグアンドドロップの場合の処理 複数ファイルの場合
        const files = _.get(e, 'dataTransfer.files')
        _.forEach(files, file => {
          this.fileList.push(file);
        });
      } else if (_.get(e, 'dataTransfer.items') && _.get(e, 'dataTransfer.items')[0]) {
        // ドラッグアンドドロップの場合の処理 一つのみの場合（ディレクトリにも対応）
        const item = _.get(e, 'dataTransfer.items')[0];
        let entry = undefined;
        if (item) {
          if (item.webkitGetAsEntry && typeof item.webkitGetAsEntry === 'function') {
            entry = item.webkitGetAsEntry();
          } else if (item.getAsEntry && typeof item.getAsEntry === 'function') {
            entry = item.getAsEntry();
          }
        }
        if (entry) await this.traverseFileTree(entry); // 専用関数にドロップされた最初のディレクトリを渡す
        else {
          this.end();
          this.$store.dispatch('Sorry, FileReader API not supported');
          return
        }
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
        return
      }

      // .DS_Storeを除外
      this.fileList = _.reject(this.fileList, file => {
        return EXCLUDES.includes(file.name);
      });

      if (!this.fileList.length) {
        this.end();
        this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: txt');
        return;
      }
      // ファイルをチェック
      // console.log(this.fileCheck(this.fileList))
      if (!this.fileCheck(this.fileList)) {
        this.end();
        return
      }

      if (typeof FileReader === 'function') {
        for (let i = 0; i < this.fileList.length; i++) {
          const file = this.fileList[i];
          await this.createFile(file)
          .then(() => {})
          .catch(() => {
            this.end();
            this.$store.dispatch('Sorry, FileReader API not working');
            return
          });
        }
        // ユーザロールがFWD以外の場合チェックファイルBFFを実行
        if(!this.isFwd) {
          await this.checkUploadFiles();
        } else {
          // ユーザーロールがFWDの場合直接アップロードBFF実行
          await this.upload()
        }
      } else {
        this.end();
        this.$store.dispatch('Sorry, FileReader API not supported');
      }
      // ファイルの入力値をリセット
      this.$refs.filesInput.value = "";
      this.$refs.directoryInput.value = "";
    },
    // ファイルを格納
    async createFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async event => {
          const fileType = file.type;
          const decodedString = fileType === 'application/pdf' ? event.target.result : await decodeBuffer(reader, file);
          this.documentsForm.documents.push({
            fileName: file.name,
            fileData: this.formatBase64(decodedString),
          });
          resolve();
        };
        reader.onerror = () => {
          reject();
        };
        reader.readAsArrayBuffer(file);
      })
    },
    // ディレクトリエントリからエントリ一覧を取得
    readAllEntries(reader) {
      return new Promise((resolve) => {
        const allEntries = [];
        const readEntries = () => {
          // reader.readEntries()は一度に最大100エントリまでしか返さないので、全てのエントリを読み終えるまでくり返し呼び出し
          // NOTE: Edge, Chromeは100エントリまで FireFoxはそのまますべてのエントリを返す
          reader.readEntries((entries) => {
            if (entries.length > 0) {
              allEntries.push(...entries);
              readEntries();
            } else {
              // 全てのエントリを読み出せたらPromiseを解決する
              resolve(allEntries);
            }
          });
        };
        readEntries();
      });
    },
    // ドラッグアンドドロップで、ディレクトリからファイルを抽出する処理
    async traverseFileTree(entry, path) {
      const _path = path || "";
      if (entry.isFile) {
        // ファイルの場合は格納する
        const file = await new Promise((resolve) => {
          entry.file((file) => {
            resolve(file);
          });
        });
        // TODO: ここの処理をどうにかできれば外だしの汎用処理にできる
        this.fileList.push(file);
      } else if (entry.isDirectory) {
        // ディレクトリ内に、サブディレクトリがある場合は、処理を繰り返す
        const directoryReader = entry.createReader();
        const entries = await this.readAllEntries(directoryReader);
        for (let i = 0; i < entries.length; i++) {
          // 再起呼び出し
          await this.traverseFileTree(entries[i], _path + entry.name + "/");
        }
      }
    },
    // 拡張子を取得します
    getFileExtension(filename) {
      const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
      return extension.toLowerCase();
    },
    // 読み取り結果をbase64に変換します
    formatBase64(str) {
      if (str) return Buffer.from(str).toString('base64');
      else return ''
    },
    // ファイルチェック
    fileCheck(files) {
      // console.log(files)
      if (files.length > 20) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[20] exceeded.');
        return false;
      }
      if (_.some(files, file => {
        if (file.name.length > 100) {
          this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
          return true;
        }
      })) {
        return false;
      }
      if (_.some(files, file => {
        if (file.size > 10485760) {
          this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
          return true;
        }
        if (file.size === 0) {
          this.$store.dispatch('SHOW_ALERT', 'Your file cannot be read');
          return true;
        }
      })) {
        return false;
      }
      if (_.some(files, file => {
        // console.log(this.getFileExtension(file.name))
        if (!['txt', 'pdf'].includes(this.getFileExtension(file.name))) {
          this.$store.dispatch('SHOW_ALERT', 'Only files with the following extensions are allowed: txt, pdf');
          return true;
        }
      })) {
        return false;
      }
      return true
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e)
    },
    async upload() {
      // bff_ncp_11 輸出許可書・輸入許可書プール登録依頼
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/nc-pool-datas/common/receive',
        },
        data: {
          registPermitInfo: this.processType === PROCESS_TYPE.ECPERMIT ? 'E' : 'D', // 許可書区分（E=輸出、D=輸入）
          files: this.documentsForm.documents
        }
      };
      $api.request(params)
      .then(res => {
        this.end();
        this.$store.dispatch('SHOW_COMPLETED', 'Accepted');
        return Promise.resolve(res);
      })
      .catch(err => {
        this.end();
        this.$store.dispatch('SHOW_ALERT', err.message);
        return Promise.reject(err);
      });
      // await new Promise((resolve) => setTimeout(resolve, 2000)) // 確認用にN秒待つ
    },
    async checkUploadFiles() {
      this.modalFiles = [];
      const checkFiles = this.documentsForm.documents.filter(file => this.getFileExtension(file.fileName) !== 'pdf');
      // チェック対象ファイルがない場合、直接アップロード処理を実行
      if (checkFiles.length === 0) {
        await this.upload();
        return;
      }
      // bff_ncp_15_輸出許可書・輸入許可書ファイルチェック 
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'post_/v1/nc-pool-datas/common/file-check',
        },
        data: {
          files: checkFiles
        }
      };

      try {
        const res = await $api.request(params);
        this.modalFiles = res.resultList.filter(file => file.permitInfo && !file.isValid);
        // モーダルに表示するファイルがある場合モーダルを表示、無い場合アップロードを実行
        if(this.modalFiles.length) {
          this.$store.commit('END_PROCESS');
          this.dialogVisible = true;
        } else {
          await this.upload();
        }
      } catch (error) {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', error.message);
      }
    },
    // チェックモーダルのOKボタン押下時の処理
    async okButtonProcess() {
      this.dialogVisible = false;
      // 選択されていないファイルをdocumentsForm.documentsから削除
      const unSelectFiles = this.modalFiles.filter(file => !file.selected);
      for (let i = this.documentsForm.documents.length - 1; i >= 0; i--){
        if (unSelectFiles.some(unSelected => unSelected.fileName === this.documentsForm.documents[i].fileName)) {
          this.documentsForm.documents.splice(i, 1);
        }
      }
      // documentsForm.documentsの中身が存在しない時の処理
      if (this.documentsForm.documents.length === 0) {
        this.$store.dispatch('SHOW_ALERT', 'No files to upload');
        return;
      }
      this.$store.commit('START_PROCESS');
      await this.upload();
    },
    cancelProcess() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
}

.attach {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  padding: 16px;

  .choose_file {
    position: relative;
    display: inline-block;

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin: 16px 0 8px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;
    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}

::v-deep .side_drawer {
  width: auto;
  width: 990px;

  >.inner {
    padding: 30px 40px 30px 35px;
  }
}

.tw_upload_drawer {
  margin: 40px 0 16px;
  max-width: 50%;
}
.modal-content {
  text-align: center;
  overflow-x: auto;
  p {
    margin: 5px 0;
  }
  
}
.file-content {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.file-checkbox {
  margin-right: 10px;
}
.file-details {
  text-align: left;
  white-space: nowrap;
}
.cancel-button {
  font-size: 12px;
  padding:  5px 15px;
}
.ok-button {
  font-size: 12px;
  padding:  5px 15px;
}
.modal-file-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dialog-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: left;
}
.scroll-content {
  overflow-y: auto;
  max-height: 500px;
}
</style>

<style lang="scss">
.check-file-dialog {
  & .el-dialog {
    width: 590px;
  }
  & .el-dialog__footer {
    padding-bottom: 20px;
    padding-right: 0px;
  }
  & .el-dialog__body {
    padding: 24px 0 10px;
  }
}
</style>