/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { WebApiClientFactory, ClientType } from 'lib-web-api-client';
import 'reflect-metadata';
import { FrontendPassportApiClient, PassportClientLoginException } from 'lib-passport-client';
import { saveAs } from 'file-saver';
import store from '@/store';
import { generateUuid } from '@/utils/searchUtil.js';
import { dummyRequest } from '@/utils/dummyApiClient';

const apiclient = WebApiClientFactory.create(ClientType.frontend, process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME);
const request = (params) => {
  if (store.state.progressBar.processing) {
    // 通信待機画面を表示する場合はProgressイベントを設定する
    params.onUploadProgress = (progressEvent)=> {
      const progress = {
        loaded: progressEvent.loaded,
        total: progressEvent.total,
      }
      store.dispatch('UPDATE_PROGRESS', progress)
    };
  }
  return new Promise((resolve, reject) => {
    // LaKeel Synergy Logic へリクエスト送信。
    apiclient.request(params)
    .then(res => {
      resolve(res.data);
    })
    .catch((err) => {
      if (err.statusCode === 401) {
        $api.authErrorHandler();
      } else if (err.statusCode === 404) {
        location.replace('/404/');
      } else if (err.isCanceled) {
        reject({
          isCanceled: err.isCanceled
        });
      } else {
        reject({
          message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
          response: _.get(err, 'response.data'),
          statusCode: err.statusCode,
        });
      }
    });
  });
};

const mockApiclient = WebApiClientFactory.create(ClientType.frontend, process.env.VUE_APP_LOCAL_MOCK_SERVER_HOSTNAME);
const mockRequest = (params) => {
  return new Promise((resolve, reject) => {
    // モックサーバー へリクエスト送信。
    mockApiclient.request(params)
      .then(res => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        if (err.statusCode === 401) {
          $api.authErrorHandler();
        } else if (err.statusCode === 404) {
          console.error(err.statusCode, err);
        } else {
          reject({
            message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
            response: _.get(err, 'response.data'),
            statusCode: err.statusCode,
          });
        }
      });
  });
};

export const $api = {
  // リクエストします
  request: (params, forDummy = { isDummyRequest: false, isError: false }) => {
    if(forDummy.isDummyRequest) return dummyRequest(params, forDummy);
    return request(params);
  },
  mockRequest: (params) => {
    return mockRequest(params);
  },
  // リクエストをキャンセルします
  cancelRequests: (tag) => {
    apiclient.cancelRequests(tag);
  },
  setCacheRequest: (data) => {
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cache-setData'
      },
      data: Object.assign({
        // serviceCode, apiCode, keyParam の値でキャッシュの保存先 Key を生成します。keyParam は文字列またはオブジェクトで設定いただけます。
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
        // キャッシュの有効期限(s)
        expireTime: 60 * 60 * 24 * 365,
        // キャッシュするまでのリクエスト回数。サーバーサイドセッションを目的として利用する場合は、1 を設定します。
        requiredRequestCount: 1
      }, data)
    };

    return request(params);
  },
  getCacheRequest: (data) => {
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cache-getData'
      },
      data: Object.assign({
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
      }, data)
    };

    return request(params);
  },
  deleteCacheRequest: (data) => {
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cache-deleteData'
      },
      data: Object.assign({
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
      }, data)
    };

    return request(params);
  },
  setMultipleCacheRequest: (data) => {
    const cacheDatas = data.map(obj => {
      return Object.assign({
        // serviceCode, apiCode, keyParam の値でキャッシュの保存先 Key を生成します。keyParam は文字列またはオブジェクトで設定いただけます。
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
        // キャッシュの有効期限(s)
        expireTime: 60 * 60 * 24 * 365,
        // キャッシュするまでのリクエスト回数。サーバーサイドセッションを目的として利用する場合は、1 を設定します。
        requiredRequestCount: 1
      }, obj);
    });
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cacheMulti-setData'
      },
      data: { cacheDatas },
    };

    return request(params);
  },
  getMultipleCacheRequest: (data) => {
    const keyParams = data.map(obj => {
      return Object.assign({
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
      }, obj);
    });
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cacheMulti-getData'
      },
      data: { keyParams },
    };

    return request(params);
  },
  deleteMultipleCacheRequest: (data) => {
    const keyParams = data.map(obj => {
      return Object.assign({
        serviceCode: `tw_${process.env.NODE_ENV}`,
        apiCode: store.state.userId,
      }, obj);
    });
    const params = {
      lslConfig: {
        serviceCode: 'lakeel-cache-api',
        apiCode: 'v1/cacheMulti-deleteData'
      },
      data: { keyParams },
    };

    return request(params);
  },
  authErrorHandler: async () => {
    // 認証エラー時FrontendPassportApiClientを呼び出してログイン画面にリダイレクトさせる。
    const passportClient = new FrontendPassportApiClient(process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME);

    try {
      // ログイン要求を行います。ログイン中でなければ、ログイン画面へ遷移します。
      /**
       * @property {string | ObjectLiteralType} params 文字列で指定される場合、テナントコードと見なします。ObjectLiteralTypeで指定する場合、「v1/auth/cookie-postRequest」APIのBodyと見なします。注意：SaaS対応の単一テナントを扱うサイトはテナントコードを指定しないでください。指定すると環境依存になってしまい、SaaSに利用できなくなります。
       * @property {undefined | string} toUrl ログイン完了後の遷移先を指定します。指定したページへ遷移させたい場合に利用してください。
       */
      await passportClient.loginRequest();
    } catch (e) {
      if (e instanceof PassportClientLoginException) {
        // 共通ログイン画面への遷移が発生します。画面遷移は非同期処理のため、なにもせず処理を終了することを推奨しております。
        // console.log('画面遷移中');
      }
      return;
    }
  },
  // ログアウト
  signOut: async () => {
    const passportClient = new FrontendPassportApiClient(process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME);
    await passportClient.logout(true, location.origin);
  },
  // ダウンロード
  download: (path, fileName, apiCode, serviceCode, download = true, query = undefined, data = undefined) => {
    return new Promise((resolve, reject) => {
      const tag = path.tag ? path.tag : undefined;
      // bff_tc_1 帳票ダウンロードBFF
      const params = {
        lslConfig: {
          serviceCode: serviceCode || 'tw-transaction-bff-api',
          apiCode: apiCode || 'get_/v1/common/file-download/{documentManageId}/{documentTrx}',
          path: _.omit(path, 'tag'),
          query: query,
        },
        responseType: 'arraybuffer',
        tag: tag,
        data
      };
      apiclient.request(params)
      .then(res => {
        if (download) {
          const name = typeof fileName === 'function' ? fileName(res.headers) : fileName;
          saveAs(new Blob([res.data], {type: res.headers['content-type']}), name);
          resolve();
        } else {
          const blob = new Blob([res.data], {type: 'application/pdf'});
          resolve(window.URL.createObjectURL(blob));
        }
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          $api.authErrorHandler();
        } else if (err.isCanceled) {
          reject({
            isCanceled: err.isCanceled
          });
        } else {
          reject({
            message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
            response: _.get(err, 'response.data'),
            statusCode: err.statusCode,
          });
        }
      });
    });
  },
  // ダウンロード
  pdfDownload: (path, fileName, serviceCode, apiCode) => {
    return new Promise((resolve, reject) => {
      // bff_ms_3 メール内容PDF取得BFF
      const params = {
        lslConfig: {
          serviceCode: serviceCode || 'tw-transaction-bff-api',
          apiCode: apiCode || 'get_/v1/email-transmission-histories/download-pdf',
          query: {
            path: path
          }
        },
        responseType: 'arraybuffer'
      };
      apiclient.request(params)
      .then(res => {
        saveAs(new Blob([res.data], {type: res.headers['content-type']}), fileName);
        resolve();
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          $api.authErrorHandler();
        } else {
          reject({
            message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
            response: _.get(err, 'response.data'),
            statusCode: err.statusCode,
          });
        }
      });
    });
  },
  // txtダウンロード
  txtDownload: (query, fileName, serviceCode, apiCode, getFileNameFromHeaders = '') => {
    return new Promise((resolve, reject) => {
      const params = {
        lslConfig: {
          serviceCode: serviceCode,
          apiCode: apiCode,
          query: query
        },
        responseType: 'arraybuffer'
      };
      apiclient.request(params)
      .then(res => {
        if (getFileNameFromHeaders) {
          // レスポンスヘッダーからファイル名取得が必要な場合、
          // getFileNameFromHeadersに対象のヘッダー項目名を指定する NOTE: ※サーバ側でレスポンスヘッダーに、[Access-Control-Expose-Headers: 対象項目]の設定があること
          let headersFileName = _.get(res, `headers[${getFileNameFromHeaders}]`);
          if (headersFileName) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(headersFileName)
            headersFileName = matches[1].replace(/['"]/g, '');
            fileName = headersFileName;
          }
        }
        saveAs(new Blob([res.data], {type: res.headers['content-type']}), fileName);
        resolve();
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          $api.authErrorHandler();
        } else {
          reject({
            message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
            response: _.get(err, 'response.data'),
            statusCode: err.statusCode,
          });
        }
      });
    });
  },
  // プールtsvダウンロード
  tsvDownload: (query, fileName, serviceCode, apiCode) => {
    return new Promise((resolve, reject) => {
      const params = {
        lslConfig: {
          serviceCode: serviceCode,
          apiCode: apiCode,
        },
        data: query,
        responseType: 'arraybuffer'
      };
      apiclient.request(params)
      .then(res => {
        saveAs(new Blob([res.data], {type: res.headers['content-type']}), fileName);
        resolve();
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          $api.authErrorHandler();
        } else {
          reject({
            message: _.get(err, 'response.data.errors[0].message') || _.get(err, 'response.data.message') || '問題が発生しました。', // errorsの1件目を返却。無ければ直下のmessageを返却。無ければ定型文を返却。
            response: _.get(err, 'response.data'),
            statusCode: err.statusCode,
          });
        }
      });
    });
  },
};

export const mixinCancelRequests = {
  data() {
    return {
      cancelRequestSources: [],
    };
  },
  beforeDestroy() {
    this.cancelRequestSources.forEach(tag => {
      $api.cancelRequests(tag);
    });
  },
  methods: {
    // リクエストキャンセル用のタグを返却します
    pushCancelTag() {
      const tag = generateUuid();
      this.cancelRequestSources.push(tag);
      return tag;
    },
  }
};

// デバッグ用ログアウトコマンド
window.twLogout = async () => {
  const passportClient = new FrontendPassportApiClient(process.env.VUE_APP_LAKEEL_SYNERGY_LOGIC_HOSTNAME);
  await passportClient.logout(true, location.origin);
}
