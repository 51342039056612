<template>
  <div class="tw_chat">
    <div class="scroller" v-chat-scroll="options">
      <div class="comments">
        <div class="comment" v-for="(comment, index) in chats" :key="`${comment.chatManageId}_${comment.chatSeq}_${index}`" :id="`comment_${comment.chatManageId}_${comment.chatSeq}`">
          <div class="from">
            <figure class="avatar table"><img :src="comment.userIconFrom"></figure>
            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">{{comment.userNameFrom}}</div>
              <div class="from_name">{{comment.userNameFrom}}</div>
            </el-tooltip>
          </div>
          <div class="date">{{comment.createDate | dateTimeFormat}}</div>
          <div v-if="comment.chatContents === null && comment.images.length" class="chat-image-wrapper">
            <!-- idがないのでkeyはindexを使用する -->
            <img v-for="(image, index) in comment.images" :key="index"  class="chat-image" :src="comment.images[index].thumbnail" @click="showFullImage(comment.images[index].imageFilePath)" />
          </div>
          <tw-chat-content v-else ref="chatContent" :content="comment.chatContents" />

          <div v-for="(doc, index) in comment.documents" :key="`${comment.chatSeq}_${index}`" class="documents">
            <span v-if="!doc.documentPath" class="file_name">{{doc.documentName}}</span>
            <a v-else class="file_name" @click.prevent="commentFileDownload(doc, comment)" tabindex="-1">{{doc.documentName}}</a>
            <div style="display: flex;justify-content:space-between;align-items:center;padding-bottom: 8px">
              {{doc.documentFileSize | fileSize}},&nbsp;{{doc.updateDate | dateTimeFormat}}
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">PDF Preview</div>
                <img v-if="doc.documentPath && s.isPdf(doc)" @click="previewPdf(doc, comment)" style="cursor:pointer;" src="@/assets/images/icons/icon_pdf_preview.svg">
              </el-tooltip>
            </div>
          </div>

          <div class="to">
            <div class="avatar_wrap" v-for="(user, index) in comment.chatDestinations" :key="`${user.mentionSeq}_${index}`" :class="{read: user.readedFlg === READ_FLG.READ}" :ref="`comment_${comment.chatManageId}`">
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{user.userNameTo}}</div>
                <figure class="avatar table"><img :src="user.userIconTo"></figure>
              </el-tooltip>
            </div>
          </div>
          <!-- <tw-drop-down :hash="`comment_${comment.chatSeq}`" /> -->

            <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
              <div slot="content">URL Copy</div>
              <tw-button class="url_copy" type="secondary" size="medium" icon="link" @click="urlCopy(`comment_${comment.chatManageId}_${comment.chatSeq}`)" />
            </el-tooltip>
        </div>
        <div v-if="!chats.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>

    </div>
    <footer ref="footer" id="chat_footer" v-if="editor && isEnable">
      <div class="message-input-wrapper">
        <div class="editor">
          <editor-content :editor="editor" />
        </div>
        <!-- <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
          <div slot="content">{{user.userName}}</div>
          <figure class="avatar table"><img :src="user.userIcon || user.userIconDefault" v-default-src="'user'"></figure>
        </el-tooltip> -->
      </div>
      <div class="footer-buttons">
        <el-button class="show-image-dialog-button" round type="text" size="small" icon="el-icon-picture" @click="isOpenRegisterImageDialog=true"></el-button>
        <div class="button-separate-line"></div>
        <button class="send" :disabled="!count" @click="onSubmit" />
      </div>
      <div v-show="count > limit" :class="{'el-form-item__error': true, 'is-show': count > limit}">Commentは{{limit}}文字以内で入力してください</div>
    </footer>

    <tw-image-paste-and-upload-dialog
      :visible.sync="isOpenRegisterImageDialog"
      @emit-image-source="uploadChatImage"
    ></tw-image-paste-and-upload-dialog>

    <el-dialog
      :visible.sync="isOpenShowImageDialog"
      class="chat-image-show-dialog"
      append-to-body
      @close="imageData = null"
    >
      <div v-loading="isImageFetching">
        <div class="chat-image-dialog-button-wrapper">
          <el-tooltip popper-class="mcu" content="Download" :tabindex="-1">
            <tw-button  type="secondary" icon="download" :disabled=" isImageFetching || existImageError" @click="downloadImage"></tw-button>
          </el-tooltip>
        </div>
        <div class="chat-image-dialog-image-wrapper">
          <img class="chat-image" :src="imageData" @error="existImageError = true" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import { ABLE_FLG, MENTION_FLG, READ_FLG, PROCESS_PROGRESS_STATUS } from 'lib-tw-common';
import tippy from 'tippy.js'
// eslint-disable-next-line no-unused-vars
import { Editor, EditorContent, Extension, VueRenderer } from '@tiptap/vue-2';
// eslint-disable-next-line import/no-extraneous-dependencies
import Document from '@tiptap/extension-document';
// eslint-disable-next-line import/no-extraneous-dependencies
import Paragraph from '@tiptap/extension-paragraph';
// eslint-disable-next-line import/no-extraneous-dependencies
import Text from '@tiptap/extension-text';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { stripTag } from '@/utils/searchUtil.js';
import { alignAToZ } from '@/utils/commonUtils.js';
import TwButton from '@/components/atoms/TwButton';
import TwChatContent from '@/components/molecules/TwChatContent';
import TwMentionList from '@/components/molecules/TwMentionList';
import TwImagePasteAndUploadDialog from '@/components/organisms/TwImagePasteAndUploadDialog';

export default {
  name: 'TwChat',
  inject: ['s'],
  components: {
    EditorContent,
    TwChatContent,
    TwButton,
    TwImagePasteAndUploadDialog
  },
  props: {
    limit: Number, // 文字数制限
  },
  data() {
    return {
      editor: null,
      content: '',
      options: {always: true, smooth: false},
      open: true,
      READ_FLG: READ_FLG,
      isOpenRegisterImageDialog: false,
      imageData: null,
      existImageError: false,
      isOpenShowImageDialog: false,
      isImageFetching: false
    }
  },
  computed: {
    chats() {
      return _.get(this, 's.res.chats') || [];
    },
    fromToInfo() {
      const from = _.get(this, 's.communicationDestinations.from');
      let fromCompany = {};
      let fromSection = {};
      let fromUsers = [];
      if(from) {
        fromCompany = {
          id: from.fromCompanyId,
          name: `From: ${from.fromCompanyId === this.s.from.fromCompanyId ? this.s.from.fromCompanyName : from.fromCompanyId}`,
          companyId: from.fromCompanyId,
          companyName: from.fromCompanyId === this.s.from.fromCompanyId ? this.s.from.fromCompanyName : ''
        }
        fromSection = from.fromSectionId ? {
          id: from.fromSectionId,
          name: from.fromSectionName,
          sectionId: from.fromSectionId,
          sectionName: from.fromSectionName,
          groupId: from.fromCompanyId
        } : {};
        fromUsers = alignAToZ((from.users || []).map(user => {
          return {
            id: user.userIdTo,
            name: user.userNameTo,
            toUserUpdateDate: user.toUserUpdateDate,
            groupId: from.fromCompanyId
          }
        }), 'name')
      }

      const to = _.get(this, 's.communicationDestinations.to');
      let toCompany = {};
      let toSection = {};
      let toUsers = [];
      if(to) {
        toCompany = {
          id: to.toCompanyId,
          name: `To: ${to.toCompanyId === this.s.to.toCompanyId ? this.s.to.toCompanyName : to.toCompanyId}`,
          companyId: to.toCompanyId,
          companyName: to.toCompanyId === this.s.to.toCompanyId ? this.s.to.toCompanyName : '',
        }
        toSection = to.toSectionId ? {
          id: to.toSectionId,
          name: to.toSectionName,
          sectionId: to.toSectionId,
          sectionName: to.toSectionName,
          groupId: to.toCompanyId
        } : {}
        toUsers = alignAToZ((to.users || []).map(user => {
          return {
            id: user.userIdTo,
            name: user.userNameTo,
            toUserUpdateDate: user.toUserUpdateDate,
            groupId: to.toCompanyId
          }
        }), 'name')
      }
      return {
        fromCompany,
        fromSection,
        fromUsers,
        toCompany,
        toSection,
        toUsers
      };
    },
    user() {
      return this.$store.state.userProfile;
    },
    users() {
      const { fromSection, fromUsers, toSection, toUsers } = this.fromToInfo;
      return _.uniqBy([ fromSection, ...fromUsers, toSection, ...toUsers], 'id')
    },
    companies() {
      return [ this.fromToInfo.fromCompany, this.fromToInfo.toCompany ]
    },
    count() {
      if (!this.editor) {
        return 0;
      }
      const content = this.editor.getHTML().replace(/<p><\/p>$/, '');
      return stripTag(content).length;
    },
    isEnable() {
      return  !this.s.isArchive && _.get(this.s, 'tradeManagement.mailChatDocumentAbleFlg') === ABLE_FLG.TRUE;
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        Document,
        Paragraph,
        Text,
        Link,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          placeholder: 'input message'
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: {
            items: query => {
              return this.users.filter(item => item.name.toLowerCase().includes(query.toLowerCase()));
            },
            render: () => {
              let component;
              let popup;

              return {
                onStart: props => {
                  component = new VueRenderer(TwMentionList, {
                    parent: this,
                    propsData: {
                      ...props,
                      groupTitles: this.companies
                    },
                  });

                  popup = tippy('#chat_footer', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  });
                },
                onUpdate(props) {
                  component.updateProps(props);

                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                  });
                },
                onKeyDown(props) {
                  if (props.event.key === 'Escape') {
                    popup[0].hide();

                    return true;
                  } else if (props.event.key === 'Enter') {
                    props.event.stopPropagation();
                  }

                  return component.ref?.onKeyDown(props);
                },
                onExit() {
                  popup[0].destroy();
                  component.destroy();
                },
              }
            },
          },
        }),
      ],
      content: '',
    }),
    setTimeout(() => {
      this.options.smooth = true;
      if (location.hash) {
        location.href = location.href;
        document.getElementById(location.hash.slice(1)).classList.add('active');
        setTimeout(() => {
          document.getElementById(location.hash.slice(1)).classList.remove('active');
        }, 2000);
      }
    })

  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    dietContent(content) {
      const domparser = new DOMParser();
      const doc = domparser.parseFromString(content, 'text/html');
      const mentions = doc.querySelectorAll('.mention');
      mentions.forEach(el => {
        el.removeAttribute('data-id');
        // el.removeAttribute('data-label');
      });
      return doc.body.innerHTML;
    },
    getMentions(content) {
      const domparser = new DOMParser();
      const doc = domparser.parseFromString(content, 'text/html');
      const mentions = doc.querySelectorAll('.mention');

      return _.uniqBy(Array.from(mentions, (el) => {
        return el.dataset.id;
      }), name => name);
    },
    submitChats() {
      const content = this.editor.getHTML();// .replace(/<p><\/p>$/, ''); // Enter押下時にできる空のPタグを除去
      this.editor.commands.setContent(content);
      console.log('コメント文字数：' + this.count);
      if (this.count > this.limit) {
        return false;
      }

      let mentions = [];
      let sectionIdTo = [];

      _.forEach(this.getMentions(content), id => {
        const target = _.find(this.users, {id: id});
        if (target.sectionId) {
          sectionIdTo.push(target.sectionId);
        } else {
          mentions.push(target);
        }
      });

      const userDestinations = mentions.map(user => {
        return {
          userIdTo: user.id,
          toUserUpdateDate: user.toUserUpdateDate
        };
      });

      const c = _.get(this, 's.communicationDestinations');

      const data = {
        fromSectionUpdateDate: _.get(c, 'from.fromSectionUpdateDate'),
        toSectionUpdateDate: _.get(c, 'to.toSectionUpdateDate'),
        // fromUserUpdateDate:  c.from.fromSectionUpdateDate,
        chats: {
          mentionFlg: mentions.length ? MENTION_FLG.ON : MENTION_FLG.OFF,
          tradingId: _.get(this.s, 'tradeManagement.tradingId'),
          tradingFlowId: _.get(this.s, 'tradeManagement.tradingFlowId'),
          entityId: _.get(this.s, 'tradeManagement.entityId'),
          processId: _.get(this.s, 'tradeManagement.processId'),
          processSeq: _.get(this.s, 'tradeManagement.processSeq'),
          processTrx: _.get(this.s, 'tradeManagement.processTrx'),
          chatManageId: _.get(_.last(this.chats), 'chatManageId'),
          chatContents: this.dietContent(content),
          userIdFrom: this.$store.state.userId,
          sectionIdTo: sectionIdTo,
          userDestinations: userDestinations
        }
      };

      this.s.submitComment(data);
      this.editor.commands.clearContent();
    },
    urlCopy(hash) {
      location.hash = hash;
      this.$copyText(location.href).then(() => {
        this.$message({
          message: 'Copied!',
          type: 'success'
        });
      }, () => {});
    },
  commentFileDownload(doc, comment, isDownload = true) {
    return new Promise((resolve, reject) => {
      const tradeManagement = _.get(this, 's.tradeManagement');
      const query = {
        documentName: doc.documentName,
        documentPath: doc.documentPath,
        tradingId: tradeManagement.tradingId,
        tradingFlowId: tradeManagement.tradingFlowId,
        entityId: tradeManagement.entityId,
        processId: tradeManagement.processId,
        processSeq: tradeManagement.processSeq,
        // processTrx: comment.processTrx, // NOTE: Ph6-2時点 不要パラメータとなったためコメントアウト
      };
      const path = {
        chatManageId: comment.chatManageId,
      }
      // bff_cr_11 コメントファイルダウンロード
      const apiCode = 'get_/v1/common/co-download/{chatManageId}';
      const serviceCode = 'tw-transaction-bff-api';
      $api.download(path, doc.documentName, apiCode, serviceCode, isDownload, query)
        .then((src) => {
          resolve(src);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          reject(err);
        });
      });
    },
    previewPdf(doc, comment) {
      const isDownload = false; // PDFプレビュー時はダウンロードしない
      this.s.pdfSrc = '@';
      this.commentFileDownload(doc, comment, isDownload)
        .then(src => {
          this.s.pdfSrc = src;
        })
        .catch(() => {});
    },
    // PB-273で追加。
    // Done/AgreedのプロセスのTo側PICユーザーは、チャット送信後に再確認依頼を実行。
    onSubmit() {
      if (this.canReconfirm()) {
        this.$store
          .dispatch(
            'SHOW_CONFIRM',
            'After posting this comment, the status of the process becomes "Input in Progress". Are you sure you want to submit?'
          )
          .then(() => {
            this.submitChats();
          })
          .then(() => {
            this.reconfirmDoneProcess();
          })
          .catch(() => {});
      } else {
        this.submitChats();
      }
    },
    async showFullImage(imagePath) {
      this.imageData = null;
      this.isOpenShowImageDialog = true;
      this.isImageFetching = true;
      const res = await this.s.getFullChatImage(imagePath);
      this.imageData = res.imageData;
      this.existImageError = res.isError;
      this.isImageFetching = false;
    },
    downloadImage() {
      let link = document.createElement('a');
      link.href = this.imageData;
      const extension = this.imageData?.split('data:image/')[1]?.split(';base64')[0];
      const fileName = extension ? `chat-image.${extension}` : 'chat-image';
      link.download=fileName;
      link.click()
    },
    async uploadChatImage(image) {
      const params = {
          tradingId: _.get(this.s, 'tradeManagement.tradingId'),
          tradingFlowId: _.get(this.s, 'tradeManagement.tradingFlowId'),
          entityId: _.get(this.s, 'tradeManagement.entityId'),
          processId: _.get(this.s, 'tradeManagement.processId'),
          processSeq: _.get(this.s, 'tradeManagement.processSeq'),
          processTrx: _.get(this.s, 'tradeManagement.processTrx'),
          chatManageId: _.get(_.last(this.chats), 'chatManageId'),
          userIdFrom: this.$store.state.userId,
          encodedImageString: image
      };
      const { isError } = await this.s.submitChatImage(params);
      if(!isError) this.isOpenRegisterImageDialog = false;
    },
    // PB-273 チャット送信時に再確認依頼を実行する条件を追加。
    canReconfirm() {
      const processStatus = this.s.tradeManagement.processProgressStatus;
      const to = this.s.roles.to; //初期表示BFFレスポンスのto

      if (processStatus !== PROCESS_PROGRESS_STATUS.AGREED &&
        processStatus !== PROCESS_PROGRESS_STATUS.DONE) {
        return false;
      }

      if (!this.$store.getters.isPic) {
        return false;
      }

      // 実行ユーザーがTo側のユーザーの時はtoに値が入っていて、そうでないときはnullが返ってくると思われる
      if (to === null || to.sectionId === null) {
        return false;
      }
      return true;
    },
    // PB-273で追加。チャット送信時にDone/AgreedのプロセスをTo側PICユーザーが再確認依頼を実行できる。
    reconfirmDoneProcess() {
      this.$emit('reconfirm');
    },
  }
};
</script>

<style lang="scss" scoped>
.tw_chat {
  position: relative;
  background: #FFFFFF;
  z-index: 2;

  .count {
    position: absolute;
    right: 50px;
    bottom: -20px;
  }

  .scroller {
    overflow-y: auto;
    height: 423px;
  }

  .comments {
    min-height: 423px;
    padding: 0 16px;
  }

  .comment {
    position: relative;
    padding: 16px 0 14px;
    border-bottom: 1px solid $color_gray_300;
    background: #FFFFFF;

    .url_copy {
      position: absolute;
      top: 16px;
      right: 0;
    }
  }

  .from {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 20px;
    color: $color_black;
    padding-right: 24px;

    .from_name {
      white-space:nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .date {
    font-size: 10px;
    line-height: 14px;
    color: #9191A4;
    margin-bottom: 4px;
  }

  .chat-image-wrapper {
    display: flex;
    justify-content: center;
    .chat-image {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .chat_contents {
    font-size: 14px;
    line-height: 20px;
    color: $color_black;
  }

  .to {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;

    .avatar_wrap {
      position: relative;
      margin-left: 9.5px;

      figure.avatar {
        margin-right: 0;
      }

      &.read:after {
        position: absolute;
        bottom: -4px;
        right: -5.5px;
        width: 12px;
        height:12px;
        background: url(../../assets/images/icons/read.png) no-repeat center;
        background-size: cover;
        content : '';
      }
    }
  }

  .editor {
    flex: 1;
    min-width: calc(100% - 22px - 24px);
    min-height: 37px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: inset 2.5px 2.5px 5px rgba(170, 170, 204, 0.5);
    background: $color_gray_100;
    border-radius: 17px;

    &::v-deep p.is-editor-empty:first-child::before {
      font-size: 12px;
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }

  footer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    left: 0;
    bottom: 0;
    min-height: 60px;
    // padding: 12px 16px 34px 52px;
    padding: 12px 16px 0 16px;
    background: $color_white;
    z-index: 3;

    .message-input-wrapper {
      position: relative;
      width: 100%;
      min-height: 30px;
    }

    .avatar {
      position: absolute;
      bottom: 6.5px;
      left:-32px;

      // has()の中の疑似クラス（:not([display="none"])）が効かないためクラスで対応
      &:has(+ .el-form-item__error.is-show) {
        bottom: calc(6.5px + 24px);
      }
    }

    ::v-deep .ProseMirror {
      border: 1px solid rgba(0,0,0,0);
      border-radius: 17px;

      &.ProseMirror-focused {
        border: 1px solid #3EC1FF;
        box-shadow: inset 2.5px 2.5px 5px rgba(170, 170, 204, 0.5);
      }
    }
    .footer-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;
    }
    .show-image-dialog-button {
      height: 36px;
      padding: 7px 15px;
      &::v-deep .el-icon-picture {
        font-size: 23px;
      }
    }
    .button-separate-line{
      border-right: 1px solid $color_gray_400;
      margin: 4px 8px;
    }
    button.send {
      display: block;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      background: url(../..//assets/images/icons/icon_airplane.svg) no-repeat 11px 9px;
      background-size: 16px auto;
      margin-bottom: 1px;
      border: 0;
      flex-shrink: 0;
      cursor: pointer;

      &:not(:disabled):hover {
        opacity: .5;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    // has()の中の疑似クラス（:not([display="none"])）が効かないためクラスで対応
    &:has(.el-form-item__error.is-show) {
      padding-bottom: 24px;
    }

    & .el-form-item__error {
      bottom: 0px;
      right: 20px;
      left: auto;
      top: auto;
      z-index: 5;
      pointer-events: none;
      background: rgba(255, 255, 255, 0.5);
      padding: 4px 8px;
      border-radius: 6px;
    }
  }

  .documents {
    width: 100%;
    min-height: 55px;
    margin-top: 8px;
    padding: 8px 8px 0 16px;
    border-radius: 6px;
    background: $color_gray_100;
    font-size: 14px;
    a.file_name, span.file_name {
      display: block;
      margin-right: 10px;
      text-decoration: none;
    }
    a.file_name {
      color: $color_dark_blue;
      cursor: pointer;
    }
  }

  // .active {
  //   animation: blur 1.5s ease-in 0s both;
  // }

  @keyframes blur {
    0% {
      box-shadow: 0 0 30px rgba($color_primary_blue, 20%);
    }
    100% {
      box-shadow: 0 0 2px rgba($color_primary_blue, 0%);
    }
  }
}

.empty_message {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: $color_black;
  padding-top: 50px;
  margin-bottom: 0;
}
</style>

<style lang="scss">
.chat-image-show-dialog {
  & .el-dialog{
    & .chat-image-dialog-image-wrapper {
      max-height: 60vh;
      max-width: 100%;
      overflow: auto;
    }
  }
  & .chat-image-dialog-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }
}
</style>